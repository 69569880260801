import React from "react"
import closeIcon from "../assets/close_icon.png"
import copyIcon from "../assets/copy_icon.svg"
import { shallowEqual, useSelector } from "react-redux";
import { AppState } from "../types/AppState";
import { delay, splitStringMiddle } from "../utils/Utils";
import ReactTooltip from "react-tooltip";

export type WalletConnectionModalProps = {
    onModalClose: () => void
}

const WalletModal: React.FC<WalletConnectionModalProps> = (
        {
            onModalClose,
        }
) => {

    const appState: AppState = useSelector(
            (state: AppState) => state,
            shallowEqual
    )
    const blockExplorerUrl = useSelector((state:AppState)=>state.blockExplorerUrl)

    return (
            <div
                    className={ `${ appState.walletState.modalConnectedOpen ? "block" : "hidden" } bg-Hover_bckgrnd grid text-center pb-10 pt-6 relative mx-auto max-w-[25vw] top-[calc(10vh)]` }>
                <button className="absolute top-6 right-6 w-8 h-8" onClick={ onModalClose }>
                    <img src={ closeIcon } className="object-contain"/>
                </button>
                <span className="text-base text-Gray_text mt-1">Connected account</span>
                <span
                        className="text-2xl font-bold text-white truncate mx-14 mt-8">{ splitStringMiddle(appState.walletState.walletAddress ?? "", 20) }</span>
                <div className="flex mt-12 mb-4 space-x-12 mx-8">
                    <div className="contents">
                        <span className="text-white text-base mt-[2px]">Copy address</span>
                        <button
                                data-event="click"
                                className="ml-2 p-1 rounded border border-transparent hover:border-ZBF_green">
                            <img
                                    data-tip="Address copied to the pasteboard!"
                                    data-for="description"
                                    className="w-4 h-4"
                                    src={ copyIcon }/>
                            <ReactTooltip
                                    id="description"
                                    effect="solid"
                                    multiline
                                    event="click"
                                    afterShow={ async () => {
                                        navigator.clipboard.writeText(appState.walletState.walletAddress ?? "")
                                        await delay(2000)
                                        ReactTooltip.hide()
                                    } }
                            />
                        </button>
                    </div>
                    <div className="contents">
                        <a className="text-ZBF_green text-base hover:underline ml-auto"
                           href={ blockExplorerUrl }
                           target="_blank">View explorer</a>
                    </div>
                </div>
            </div>
    )
}

export default WalletModal
import React from "react"
import NFTItem from "./NFTItem";
import TokenMintSpinner from "../templates/TokenMintSpinner";
import { SCAPI } from "../network/explorer_types/SCAPI";

export type NFTsListProps = {
    isLoading: boolean
    isEmpty: boolean
    baseURI: string
    collectionSymbol: string
    collectionName: string
    titleSection: JSX.Element
    filteredNFTs: SCAPI.TokenNonFungibleBoxType[]
}

const NFTsList: React.FC<NFTsListProps> = (
        {
            isLoading,
            isEmpty,
            baseURI,
            collectionSymbol,
            collectionName,
            titleSection,
            filteredNFTs
        }
) => {
    const nfts = () => {
        return <div className={ `grid grid-cols-4 -ml-4` }>
            { filteredNFTs.map((nft, i) => (
                    <div className={ `${ i > 3 ? "mt-4" : "mt-0" } ml-4` }>
                        <NFTItem baseURI={ baseURI } collectionSymbol={ collectionSymbol } collectionName={collectionName} nft={ filteredNFTs[i] }/>
                    </div>
            )) }
        </div>
    }

    return (
            <div className={"mt-4"}>
                { titleSection }
                <div className={ `py-4 overflow-y-auto` }>
                    <div>
                        { filteredNFTs.length > 0 && (
                                nfts()
                        ) }

                        { isEmpty && (
                                <p className="text-center text-Gray_text text-xl my-4">
                                    No elements found
                                </p>
                        ) }

                        { isLoading && (
                                <div className="w-20 h-20 my-12 mx-auto">
                                    <TokenMintSpinner/>
                                </div>
                        ) }
                    </div>
                </div>
            </div>
    )
}

export default NFTsList
import React from "react"
import closeIcon from "../assets/close_icon.png"
import warningIcon from "../assets/warning_sign.svg"
import TokenMintSpinner from "../templates/TokenMintSpinner";
import { RequestStatus } from "../types/AppState";

export type TokenCreatorCreationModalProps = {
    open: boolean
    onModalClose: () => void
    state: RequestStatus,
    errorMessage?: string
}

const TokenCreatorCreationModal: React.FC<TokenCreatorCreationModalProps> = (
        {
            open,
            onModalClose,
            state,
            errorMessage
        }
) => {

    const modalTitle = () => {
        switch (state) {
            case RequestStatus.CONNECTING:
                return "Opening Cobalt Wallet"
            case RequestStatus.DECLARING_TOKEN:
            case RequestStatus.MINTING_TOKEN:
            case RequestStatus.DECLARING_COLLECTION:
            case RequestStatus.MINTING_NFT:
                return "Submitting transaction"
            case RequestStatus.DECLARING_TOKEN_COMPLETED:
                return "Token created"
            case RequestStatus.MINTING_TOKEN_COMPLETED:
                return "Token minted"
            case RequestStatus.DECLARING_COLLECTION_COMPLETED:
                return "Collection created"
            case RequestStatus.MINTING_NFT_COMPLETED:
                return "NFT minted"
            case RequestStatus.ERROR:
                return "Connection failed"
            default:
                return ""
        }
    }

    const modalSubtitle = () => {
        switch (state) {
            case RequestStatus.CONNECTING:
                return "Connecting"
            case RequestStatus.DECLARING_TOKEN:
                return "Creating token"
            case RequestStatus.MINTING_TOKEN:
                return "Minting tokens"
            case RequestStatus.BURNING_TOKEN:
                return "Burning tokens"
            case RequestStatus.DECLARING_TOKEN_COMPLETED:
            case RequestStatus.DECLARING_COLLECTION_COMPLETED:
            case RequestStatus.MINTING_TOKEN_COMPLETED:
            case RequestStatus.MINTING_NFT_COMPLETED:
            case RequestStatus.BURNING_TOKEN_COMPLETED:
                return "Congratulations!"
            case RequestStatus.ERROR:
                return "Error connecting"
            default:
                return ""
        }
    }

    // TODO: Need links here!!
    const infoBlock = () => {
        switch (state) {
            case RequestStatus.CONNECTING:
                return <p>Stuck connecting? Make sure you have created an account in Cobalt Wallet first.</p>
            case RequestStatus.DECLARING_COLLECTION:
            case RequestStatus.DECLARING_COLLECTION_COMPLETED:
                return <p>Collection creation usually takes up to around 2 minutes and 30 seconds after submitting the
                    transaction. To find out more click <a
                            className="hover:underline text-ZBF_green" href="https://www.horizen.io">here.</a></p>
            case RequestStatus.MINTING_TOKEN:
            case RequestStatus.MINTING_NFT:
                return <p>Minting can take up to 5 minutes after submitting the transaction. Learn more <a
                        className="hover:underline text-ZBF_green" href="https://www.horizen.io">here.</a></p>
            case RequestStatus.MINTING_TOKEN_COMPLETED:
                return <p>Your balance will appear once your mint transaction is recorded in the next block.</p>
            case RequestStatus.MINTING_NFT_COMPLETED:
                return <p>Your NFT will appear once your mint transaction is recorded in the next block.</p>
            case RequestStatus.BURNING_TOKEN:
                return <p>Burning can take up to 5 minutes after submitting the transaction. Learn more <a
                        className="hover:underline text-ZBF_green" href="https://www.horizen.io">here.</a></p>
            case RequestStatus.BURNING_TOKEN_COMPLETED:
                return <p>Supplies will update once your burn transaction is recorded in the next block.</p>
            case RequestStatus.ERROR:
                return <p>{ errorMessage }</p>
            default:
                return <p>Token creation usually takes up to around 2 minutes and 30 seconds after submitting the
                    transaction. To find out more click <a
                            className="hover:underline text-ZBF_green" href="https://www.horizen.io">here.</a></p>
        }
    }

    const showSpinner = () => {
        switch (state) {
            case RequestStatus.CONNECTING:
            case RequestStatus.DECLARING_TOKEN:
            case RequestStatus.MINTING_TOKEN:
                return true
            case RequestStatus.DECLARING_TOKEN_COMPLETED:
            case RequestStatus.MINTING_TOKEN_COMPLETED:
            case RequestStatus.ERROR:
                return false
            default:
                return true
        }
    }

    return (
            <div
                    className={ `${ open ? "block" : "hidden" } bg-Hover_bckgrnd grid text-center py-10 relative mx-auto max-w-[25vw] top-[calc(10vh)]` }>
                <button className="absolute top-6 right-6 w-8 h-8" onClick={ onModalClose }>
                    <img src={ closeIcon } className="object-contain"/>
                </button>
                { state === RequestStatus.ERROR ? (
                        <img className="mx-auto h-14 w-14 mt-6" src={ warningIcon }/>
                ) : (
                        showSpinner() && (
                                <div className="w-14 mx-auto">
                                    <TokenMintSpinner/>
                                </div>
                        )
                ) }

                <span className="text-normal text-Gray_text mt-10">{ modalTitle() }</span>
                <span className="text-3xl font-bold text-white mt-2">{ modalSubtitle() }</span>
                <span
                        className="text-normal text-white mt-10 bg-Main_bckgrnd max-w-[80%] mx-auto px-8 py-4">{ infoBlock() }</span>
            </div>
    )
}

export default TokenCreatorCreationModal
import React, { Dispatch, useEffect, useState } from "react"
import ProgressPoint, { ConnectionSide, ProgressStatus } from "../../components/Common/ProgressPoint"
import { WalletStatus } from "../../types/WalletState";
import {
    getAllCollections,
    getTransactionsByToken,
    sendCollectionDeclare,
    sendNFTMint
} from "../../network/APIRepository";
import { Constants } from "../../utils/Constants";
import { delay } from "../../utils/Utils";
import { AppState, blurBackground } from "../../types/AppState";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
    CLOSE_MODALS,
    COLLECTION_DECLARATION_COMPLETED,
    COLLECTION_DECLARATION_CONFIRMED,
    COLLECTION_DECLARATION_STARTED,
    COLLECTION_MINT_COMPLETED,
    COLLECTION_MINT_STARTED,
    TOKEN_DECLARATION_MODAL_CLOSED,
    TOKEN_MINT_MODAL_CLOSED,
    WALLET_NOT_CONNECTED_CLICK
} from "../../store/actionTypes";
import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import { SCAPI } from "../../network/explorer_types/SCAPI";
import { TransactionJSON } from "../../network/explorer_types/Transaction";
import { TokenchainAPI } from "../../network/explorer_types/TokenchainAPI";
import CollectionCreatorCollectionInfo, {
    collectionCreationFormIsValid
} from "../../components/CollectionCreatorCollectionInfo/CollectionCreatorCollectionInfo";
import CollectionCreatorMintInfo, { collectionMintFormIsValid } from "../../components/CollectionCreatorMintInfo";
import { URLProvider } from "../../utils/URLProvider";

const CollectionCreator: React.FC = () => {

    const navigate = useNavigate()
    const [creationStep, setCreationStep] = useState(0)

    const appState: AppState = useSelector(
            (state: AppState) => state,
            shallowEqual
    )

    const dispatch: Dispatch<any> = useDispatch()

    const [collectionUUID, setCollectionUUID] = useState<string | undefined>(undefined)
    const [pendingCollectionTransactionId, setPendingCollectionTransactionId] = useState<string | undefined>(undefined)

    // TokenInfo
    const [name, setName] = useState<string | undefined>(undefined)
    const [symbol, setSymbol] = useState<string | undefined>(undefined)
    const [supply, setSupply] = useState<string | undefined>(undefined)
    const [supplyTemp, setSupplyTemp] = useState<string | undefined>(undefined)
    const [termsAndConditions, setTermsAndConditions] = useState(false)
    const [unlimitedSupply, setUnlimitedSupply] = useState(false)
    const [baseURI, setBaseURI] = useState<string | undefined>(undefined)

    // TokenMint
    const [address, setAddress] = useState<string | undefined>(undefined)

    const {
        data: tokenTxs,
    } = useQuery<any>(["getTransactionsByToken", collectionUUID!], () => getTransactionsByToken(collectionUUID as string, undefined), {
        enabled: appState.walletState.pendingCollectionCreation && collectionUUID !== undefined,
        onSuccess: ((resp) => {
            const tx: any = resp[0].find((tx: SCAPI.Transaction & TransactionJSON) => tx.typeName === SCAPI.TokenChainTransactionTypes.TokenTypeDeclareTransaction)
            if (tx) {
                const tokenDeclareTx = tx as unknown as TransactionJSON & TokenchainAPI.TokenDeclareTransactionType
                setPendingCollectionTransactionId(tokenDeclareTx.txid)
            }
        })
    })

    const {
        data: newlyCreatedToken,
    } = useQuery<Array<SCAPI.TokenBoxType> | void>(["getCollection", collectionUUID], () => getAllCollections([collectionUUID ?? ""]), {
        enabled: appState.walletState.pendingCollectionCreation && collectionUUID !== undefined,
        refetchInterval: 20000,
        onSuccess: ((resp) => {
            if (resp && resp.length > 0) {
                dispatch({
                    type: COLLECTION_DECLARATION_CONFIRMED
                })
            }
        })
    })

    //////////////////// Effects //////////////////////

    useEffect(() => {
        if (unlimitedSupply) {
            setSupplyTemp(supply)
            setSupply("0")
        } else {
            if (supplyTemp) {
                setSupply(supplyTemp)
            }
        }
    }, [unlimitedSupply])

    //////////////////////////////////////////////////////

    const onSubmit = () => {
        if (appState.walletState.walletStatus !== WalletStatus.CONNECTED) {
            dispatch({
                type: WALLET_NOT_CONNECTED_CLICK
            })
            return
        }

        switch (creationStep) {
            case 0:
                if (!collectionCreationFormIsValid(name, symbol, baseURI, supply, termsAndConditions, unlimitedSupply)) {
                    return
                }

                dispatch({
                    type: COLLECTION_DECLARATION_STARTED
                })

                sendCollectionDeclare(
                        symbol!,
                        name!,
                        supply!,
                        appState.walletState.walletAddress!,
                        baseURI!
                ).then(async (newTokenUUID) => {
                    dispatch({
                        type: COLLECTION_DECLARATION_COMPLETED
                    })

                    setCollectionUUID(newTokenUUID)
                    await delay(3500)

                    dispatch({
                        type: CLOSE_MODALS
                    })

                    setCreationStep(2)

                }).catch((error) => {
                    if (error.message.includes("closed")) {
                        dispatch({
                            type: TOKEN_DECLARATION_MODAL_CLOSED
                        })
                    }
                    if (error.message.includes("rejected")) {
                        dispatch({
                            type: CLOSE_MODALS
                        })
                    }
                })

                break
            case 2:
                if (!collectionMintFormIsValid(address)) {
                    return
                }

                if (!collectionUUID) {
                    return
                }

                sendNFTMint(
                        address!,
                        collectionUUID,
                        1,
                ).then(async (resp) => {
                    dispatch({
                        type: COLLECTION_MINT_COMPLETED
                    })

                    await delay(3000)

                    dispatch({
                        type: CLOSE_MODALS
                    })

                    navigate(URLProvider.URL_NFTs)

                }).catch((error) => {
                    if (error.message.includes("closed")) {
                        dispatch({
                            type: TOKEN_MINT_MODAL_CLOSED
                        })
                    }
                    if (error.message.includes("rejected")) {
                        dispatch({
                            type: CLOSE_MODALS
                        })
                    }
                })

                dispatch({
                    type: COLLECTION_MINT_STARTED
                })
        }
    }

    const progressStep = (title: string, status: ProgressStatus, step: number, totalSteps: number) => {
        let connectionSide = ConnectionSide.CENTER
        if (step === 0) {
            connectionSide = ConnectionSide.RIGHT
        } else if (step === totalSteps - 1) {
            connectionSide = ConnectionSide.LEFT
        }

        return (
                <div className="grid col-span-1 text-center pb-2">
                    <p className={ `${ creationStep === step ? "text-white font-bold" : "text-Content_gray font-normal" } text-base mb-3` }>{ title }</p>
                    < div className="flex">
                        <ProgressPoint status={ status } connectionSide={ connectionSide }/>
                    </div>
                </div>
        )
    }

    const creatorTabs = () => {
        return (
                <div className={ "grid-cols-2 grid mb-6 mx-20" }>
                    { progressStep("Create collection", creationStep === 0 ? ProgressStatus.CURRENT : ProgressStatus.DONE, 0, 3) }
                    { progressStep("Mint NFT", creationStep === 2 ? ProgressStatus.CURRENT : (creationStep < 2 ? ProgressStatus.NOT_DONE : ProgressStatus.DONE), 2, 3) }
                </div>
        )
    }

    const creatorContent = () => {
        switch (creationStep) {
            case 0:
                return <CollectionCreatorCollectionInfo
                        name={ name } setName={ (newName) => setName(newName) }
                        symbol={ symbol } setSymbol={ (newSymbol) => setSymbol(newSymbol) }
                        supply={ supply } setSupply={ (newSupply) => setSupply(newSupply) }
                        baseURI={ baseURI } setBaseURI={ (newBaseURI) => setBaseURI(newBaseURI) }
                        termsAndConditions={ termsAndConditions }
                        setTermsAndConditions={ (newTermsAndConditions) => setTermsAndConditions(newTermsAndConditions) }
                        unlimitedSupply={ unlimitedSupply }
                        onUnlimitedSupplyChange={ () => setUnlimitedSupply(!unlimitedSupply) }
                        onSubmit={ onSubmit }
                />
            case 2:
                return <CollectionCreatorMintInfo tokenUUID={ collectionUUID }
                                                  pendingCollectionTransactionId={ pendingCollectionTransactionId }
                                                  address={ address }
                                                  setAddress={ (newAddress) => setAddress(newAddress) }
                                                  onSubmit={ onSubmit }
                />
        }
    }

    return (
            <div>
                <main>
                    <div
                            className="max-w-screen-xl ml-auto mr-auto grid">
                        <span
                                className={ `${ blurBackground(appState) ? "blur-sm" : "" } mx-auto text-4xl font-bold text-white text-center mt-24` }>Create an NFT collection</span>
                        <span
                                className={ `${ blurBackground(appState) ? "blur-sm" : "" } mx-auto text-base text-Content_gray text-center mt-8 px-80` }>Create an NFT collection with a fixed or unlimited item supply.</span>
                        <div className={ `${ blurBackground(appState) ? "blur-sm" : "" } mx-10 px-64 py-10` }>
                            { creatorTabs() }
                            { creatorContent() }
                        </div>
                    </div>
                </main>
            </div>
    )
}

export default CollectionCreator
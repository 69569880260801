import React, { useEffect, useState } from "react"
import { useQuery } from "react-query"
import ReactSwitch from "react-switch"
import { getTxFees } from "../../network/APIRepository"
import { isValidURI, minimumUnitsToFormattedString, quantityIsValid } from "../../utils/Utils"
import TokenmintButton, { TokenmintButtonStyle } from "../Common/TokenmintButton"
import FormInput, { FormInputStyle } from "../Form/FormInput"
import TokenMintSpinner from "../../templates/TokenMintSpinner";
import FormCheckbox from "../Form/FormCheckbox";
import BigNumber from "bignumber.js";

export type CollectionCreatorCollectionInfoProps = {
    name: string | undefined
    setName: (name: string) => void
    symbol: string | undefined
    setSymbol: (symbol: string) => void
    supply: string | undefined
    setSupply: (supply: string) => void
    baseURI: string | undefined
    setBaseURI: (baseURI: string) => void
    termsAndConditions: boolean
    setTermsAndConditions: (termsAndConditions: boolean) => void
    unlimitedSupply: boolean
    onUnlimitedSupplyChange: () => void

    onSubmit: () => void
}

export const collectionCreationFormIsValid = (name: string | undefined, symbol: string | undefined, baseURI: string | undefined, supply: string | undefined, termsAndConditions: boolean, unlimitedSupply: boolean) => {
    const amountRegex = new RegExp("^[0-9]{1,78}$")
    const symbolRegex = new RegExp("[A-Za-z0-9]{3,6}")
    const baseURIIsValid = isValidURI(baseURI) && baseURI?.charAt(baseURI?.length - 1) === "/"

    let supplyValid = false
    if (supply) {
        let supplyNumber = new BigNumber(supply)
        if (supplyNumber.eq(0)) {
            supplyValid = unlimitedSupply
        } else {
            supplyValid = supplyNumber.isGreaterThan(0)
        }
    }

    return name !== undefined && name !== ""
            && symbol !== undefined && symbol !== "" && symbolRegex.test(symbol)
            && supply !== undefined && supply !== "" && amountRegex.test(supply) && quantityIsValid(supply) && supplyValid
            && baseURIIsValid
            && termsAndConditions
}

const CollectionCreatorCollectionInfo: React.FC<CollectionCreatorCollectionInfoProps> = (
        {
            name,
            setName,
            symbol,
            setSymbol,
            supply,
            setSupply,
            baseURI,
            setBaseURI,
            termsAndConditions,
            setTermsAndConditions,
            unlimitedSupply,
            onUnlimitedSupplyChange,
            onSubmit
        }
) => {

    const [validForm, setValidForm] = useState(false)
    const [supplyFormatted, setSupplyFormatted] = useState<string | undefined>(undefined)
    const [supplyError, setSupplyError] = useState<string | undefined>(undefined)
    const [baseURIError, setBaseURIError] = useState<string | undefined>(undefined)

    const {
        data: fees,
        error: feeError,
        isLoading: feesIsLoading
    } = useQuery(['getTxFee'], () => getTxFees(), {retry: false})

    //////////////////// Effects //////////////////////

    useEffect(() => {
        if (feesIsLoading) {
            setValidForm(false)
            return
        }

        const validForm = collectionCreationFormIsValid(name, symbol, baseURI, supply, termsAndConditions, unlimitedSupply)
        setValidForm(validForm)
    }, [name, symbol, supply, fees, feeError, baseURI, termsAndConditions])

    useEffect(() => {
        if (supply) {
            setSupplyError(quantityIsValid(supply) ? "" : "Exceeds max limit")
        }
        setSupplyFormatted(minimumUnitsToFormattedString(supply, 0))
    }, [supply])

    useEffect(() => {
        if (baseURI === "" || baseURI === undefined) {
            setBaseURIError(undefined)
        } else {
            const isValid = isValidURI(baseURI)
            if (!isValid) {
                setBaseURIError("Invalid URI")
            } else if (baseURI.charAt(baseURI.length - 1) !== "/") {
                setBaseURIError("Base URI must finish with /")
            } else {
                setBaseURIError(undefined)
            }
        }
    }, [baseURI])

    //////////////////////////////////////////////////////

    return (
            <div className="grid mt-16">
                <div className="grid space-y-4">
                    <FormInput title="Collection name" titleTooltip="The collection full name" value={ name }
                               onChange={ setName }/>
                    <FormInput title="Collection symbol"
                               titleTooltip={ <p>The symbol needs to be 3 to 6<br/>characters long and include only<br/>letters
                                   and numbers</p> }
                               value={ symbol } onChange={ (value) => {
                        const newValue = value.replace(/[^A-Za-z0-9]/g, '').substring(0, 6)
                        setSymbol(newValue)
                    } }/>
                    <FormInput title="Base URI"
                               titleTooltip="The URI where the collection is stored"
                               value={ baseURI }
                               placeholder="ipfs://sample/"
                               error={ baseURIError }
                               onChange={ setBaseURI }/>
                    <FormInput title="Item Supply"
                               titleTooltip="The amount of NFTs in the collection"
                               disabled={ unlimitedSupply }
                               value={ unlimitedSupply ? "Unlimited" : supplyFormatted }
                               error={ supplyError }
                               onChange={ (value) => {
                                   const newValue = value.replace(/\D/g, '').substring(0, 78)
                                   setSupply(newValue)
                               } }/>
                    <FormCheckbox title={ "Unlimited supply" } value={ unlimitedSupply } disabled={ false }
                                  onChange={ onUnlimitedSupplyChange }/>
                    { feesIsLoading &&
                            <div className="w-10 mx-auto"
                                 data-testid="fee-spinner-container">
                                <TokenMintSpinner/>
                            </div>
                    }
                    { fees &&
                            <FormInput title="Collection creation fee"
                                       titleTooltip={ <p>The required fee for a collection<br/>declaration transaction
                                       </p> }
                                       value={ fees.NFTDeclareFee.toString() }
                                       style={ FormInputStyle.BLACK }
                                       onChange={ () => {
                                       } }
                                       disabled={ true }/>
                    }
                </div>
                <div className="grid mt-12">
            <span
                    className="text-base tracking-[0.2em] uppercase text-Gray_text font-bold">Terms and conditions</span>
                    <div className="block mt-2">
                <span className="text-base text-white mr-4 pt-[1px]">I agree to the <a
                        className="text-ZBF_green hover:underline"
                        href={ process.env.REACT_APP_TOKENMINT_URL!.concat("/terms") }>Terms and Conditions</a></span>
                        <ReactSwitch className={ `${ termsAndConditions ? "active" : "inactive" } float-right` }
                                     data-testid={ "terms-and-conditions-switch" }
                                     checked={ termsAndConditions } disabled={ false }
                                     onChange={ () => setTermsAndConditions(!termsAndConditions) }
                                     onColor={ "#26DB8D" } offColor={ "#7A7E8C" }
                                     height={ 10 } width={ 26 } handleDiameter={ 28 }
                                     checkedIcon={ false }
                                     uncheckedIcon={ false }/>
                    </div>
                </div>
                <TokenmintButton
                        title="Confirm"
                        dataTestId={ "collection-creator-collection-info-confirm-button" }
                        style={ TokenmintButtonStyle.GREEN_BORDERED }
                        enabled={ validForm }
                        additionalClasses="mt-14"
                        onClick={ onSubmit }>
                </TokenmintButton>
            </div>
    )
}

export default CollectionCreatorCollectionInfo
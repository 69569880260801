import React, { Dispatch, useEffect, useState } from "react"
import ProgressPoint, { ConnectionSide, ProgressStatus } from "../components/Common/ProgressPoint"
import TokenCreatorMintInfo, { tokenMintFormIsValid } from "../components/TokenCreatorMintInfo"
import TokenCreatorTokenInfo, { tokenCreationFormIsValid } from "../components/TokenCreatorTokenInfo"
import { WalletStatus } from "../types/WalletState";
import BigNumber from "bignumber.js";
import { getSingleToken, getTransactionsByToken, sendTokenDeclare, sendTokenMint } from "../network/APIRepository";
import { Constants } from "../utils/Constants";
import { delay } from "../utils/Utils";
import { AppState, blurBackground } from "../types/AppState";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
    CLOSE_MODALS,
    TOKEN_DECLARATION_COMPLETED,
    TOKEN_DECLARATION_CONFIRMED,
    TOKEN_DECLARATION_MODAL_CLOSED,
    TOKEN_DECLARATION_STARTED,
    TOKEN_MINT_COMPLETED,
    TOKEN_MINT_MODAL_CLOSED,
    TOKEN_MINT_STARTED,
    WALLET_NOT_CONNECTED_CLICK
} from "../store/actionTypes";
import { URLProvider } from "../utils/URLProvider";
import { useNavigate } from "react-router-dom";
import { FeatureFlag, featureFlags, FeatureFlagType } from "../utils/FeatureFlags";
import { useQuery } from "react-query";
import { SCAPI } from "../network/explorer_types/SCAPI";
import { TransactionJSON } from "../network/explorer_types/Transaction";
import { TokenchainAPI } from "../network/explorer_types/TokenchainAPI";

const TokenCreator: React.FC = () => {

    const navigate = useNavigate()
    const [creationStep, setCreationStep] = useState(0)

    const appState: AppState = useSelector(
            (state: AppState) => state,
            shallowEqual
    )

    const dispatch: Dispatch<any> = useDispatch()

    const [tokenUUID, setTokenUUID] = useState<string | undefined>(undefined)
    const [pendingTokenTransactionId, setPendingTokenTransactionId] = useState<string | undefined>(undefined)

    // TokenInfo
    const [name, setName] = useState<string | undefined>(undefined)
    const [symbol, setSymbol] = useState<string | undefined>(undefined)
    const [supply, setSupply] = useState<string | undefined>(undefined)
    const [supplyTemp, setSupplyTemp] = useState<string | undefined>(undefined)
    const [termsAndConditions, setTermsAndConditions] = useState(false)
    const [burnable, setBurnable] = useState(true)
    const [mintable, setMintable] = useState(true)
    const [unlimitedSupply, setUnlimitedSupply] = useState(false)
    const [customizableDecimals, setCustomizableDecimals] = useState(false)
    const [customizableDecimalsValue, setCustomizableDecimalsValue] = useState(6)

    // TokenMint
    const [address, setAddress] = useState<string | undefined>(undefined)
    const [amount, setAmount] = useState<string | undefined>(undefined)

    const {
        data: tokenTxs,
    } = useQuery<any>(["getTransactionsByToken", tokenUUID!], () => getTransactionsByToken(tokenUUID as string, undefined), {
        enabled: appState.walletState.pendingTokenCreation && tokenUUID !== undefined,
        onSuccess: ((resp) => {
            const tx: any = resp[0].find((tx: SCAPI.Transaction & TransactionJSON) => tx.typeName === SCAPI.TokenChainTransactionTypes.TokenTypeDeclareTransaction)
            if (tx) {
                const tokenDeclareTx = tx as unknown as TransactionJSON & TokenchainAPI.TokenDeclareTransactionType
                setPendingTokenTransactionId(tokenDeclareTx.txid)
            }
        })
    })

    const {
        data: newlyCreatedToken,
    } = useQuery<SCAPI.TokenBoxType | void>(["getToken", tokenUUID], () => getSingleToken(tokenUUID ?? ""), {
        enabled: appState.walletState.pendingTokenCreation && tokenUUID !== undefined,
        refetchInterval: 20000,
        onSuccess: ((resp) => {
            if (resp) {
                dispatch({
                    type: TOKEN_DECLARATION_CONFIRMED
                })
            }
        })
    })

    //////////////////// Effects //////////////////////

    useEffect(() => {
        if (unlimitedSupply) {
            setSupplyTemp(supply)
            setSupply("0")
        } else {
            if (supplyTemp) {
                setSupply(supplyTemp)
            }
        }
    }, [unlimitedSupply])

    //////////////////////////////////////////////////////

    const onSubmit = () => {
        if (appState.walletState.walletStatus !== WalletStatus.CONNECTED) {
            dispatch({
                type: WALLET_NOT_CONNECTED_CLICK
            })
            return
        }

        switch (creationStep) {
            case 0:
                if (!tokenCreationFormIsValid(name, symbol, supply, customizableDecimalsValue, termsAndConditions)) {
                    return
                }

                dispatch({
                    type: TOKEN_DECLARATION_STARTED
                })

                sendTokenDeclare(
                        symbol!,
                        name!,
                        customizableDecimalsValue,
                        supply!,
                        appState.walletState.walletAddress!,
                        {}
                ).then(async (newTokenUUID) => {
                    dispatch({
                        type: TOKEN_DECLARATION_COMPLETED
                    })

                    setTokenUUID(newTokenUUID)
                    await delay(3500)

                    dispatch({
                        type: CLOSE_MODALS
                    })

                    setCreationStep(2)

                }).catch((error) => {
                    if (error.message.includes("closed")) {
                        dispatch({
                            type: TOKEN_DECLARATION_MODAL_CLOSED
                        })
                    }
                    if (error.message.includes("rejected")) {
                        dispatch({
                            type: CLOSE_MODALS
                        })
                    }
                })

                break
            case 2:
                if (!tokenMintFormIsValid(address, amount, new BigNumber(supply!), customizableDecimalsValue)) {
                    return
                }

                if (!tokenUUID) {
                    return
                }

                sendTokenMint(
                        address!,
                        tokenUUID,
                        amount!
                ).then(async (resp) => {
                    dispatch({
                        type: TOKEN_MINT_COMPLETED
                    })

                    await delay(3000)

                    dispatch({
                        type: CLOSE_MODALS
                    })

                    navigate(URLProvider.URL_HOME)

                }).catch((error) => {
                    if (error.message.includes("closed")) {
                        dispatch({
                            type: TOKEN_MINT_MODAL_CLOSED
                        })
                    }
                    if (error.message.includes("rejected")) {
                        dispatch({
                            type: CLOSE_MODALS
                        })
                    }
                })

                dispatch({
                    type: TOKEN_MINT_STARTED
                })
        }
    }

    const progressStep = (title: string, status: ProgressStatus, step: number, totalSteps: number) => {
        let connectionSide = ConnectionSide.CENTER
        if (step === 0) {
            connectionSide = ConnectionSide.RIGHT
        } else if (step === totalSteps - 1) {
            connectionSide = ConnectionSide.LEFT
        }

        return (
                <div className="grid col-span-1 text-center pb-2">
                    <p className={ `${ creationStep === step ? "text-white font-bold" : "text-Content_gray font-normal" } text-base mb-3` }>{ title }</p>
                    < div className="flex">
                        <ProgressPoint status={ status } connectionSide={ connectionSide }/>
                    </div>
                </div>
        )
    }

    const creatorTabs = () => {
        const communityInfoFeatureFlag: FeatureFlag | undefined = featureFlags().find((item) => item.flag === FeatureFlagType.TOKEN_CREATOR_COMMUNITY_INFO_TAB)

        return (
                <div className={ `${ communityInfoFeatureFlag?.enabled ? "grid-cols-3" : "grid-cols-2" } grid mb-6 mx-20` }>
                    { progressStep("Token Info", creationStep === 0 ? ProgressStatus.CURRENT : ProgressStatus.DONE, 0, 3) }
                    { communityInfoFeatureFlag?.enabled && (
                            progressStep(
                                    "Community Info",
                                    creationStep === 1 ? ProgressStatus.CURRENT : (creationStep < 1 ? ProgressStatus.NOT_DONE : ProgressStatus.DONE), 1, 3)
                    ) }
                    { progressStep("Mint Tokens", creationStep === 2 ? ProgressStatus.CURRENT : (creationStep < 2 ? ProgressStatus.NOT_DONE : ProgressStatus.DONE), 2, 3) }
                </div>
        )
    }

    const creatorContent = () => {
        switch (creationStep) {
            case 0:
                return <TokenCreatorTokenInfo
                        name={ name } setName={ (newName) => setName(newName) }
                        symbol={ symbol } setSymbol={ (newSymbol) => setSymbol(newSymbol) }
                        supply={ supply } setSupply={ (newSupply) => setSupply(newSupply) }
                        termsAndConditions={ termsAndConditions }
                        setTermsAndConditions={ (newTermsAndConditions) => setTermsAndConditions(newTermsAndConditions) }
                        burnable={ burnable } onBurnableChange={ () => setBurnable(!burnable) }
                        mintable={ mintable } onMintableChange={ () => setMintable(!mintable) }
                        unlimitedSupply={ unlimitedSupply }
                        onUnlimitedSupplyChange={ () => setUnlimitedSupply(!unlimitedSupply) }
                        customizableDecimals={ customizableDecimals }
                        onCustomizableDecimalsChange={ () => {
                            setCustomizableDecimals(!customizableDecimals)
                        } }
                        customizableDecimalsValue={ customizableDecimalsValue }
                        onCustomizableDecimalsValueChange={ (newValue) => setCustomizableDecimalsValue(newValue) }
                        onSubmit={ onSubmit }
                />
            case 2:
                return <TokenCreatorMintInfo tokenUUID={ tokenUUID }
                                             pendingTokenTransactionId={ pendingTokenTransactionId }
                                             maxSupply={ new BigNumber(supply!) }
                                             precision={ customizableDecimalsValue }
                                             address={ address }
                                             setAddress={ (newAddress) => setAddress(newAddress) }
                                             amount={ amount }
                                             setAmount={ (newAmount) => setAmount(newAmount) }
                                             onSubmit={ onSubmit }
                />
        }
    }

    return (
            <div>
                <main>
                    <div
                            className="max-w-screen-xl ml-auto mr-auto grid">
                        <span
                                className={ `${ blurBackground(appState) ? "blur-sm" : "" } mx-auto text-4xl font-bold text-white text-center mt-24` }>Create a token</span>
                        <span
                                className={ `${ blurBackground(appState) ? "blur-sm" : "" } mx-auto text-base text-Content_gray text-center mt-8 px-80` }>Create a token with a fixed or unlimited supply. To learn more about how to mint or use tokens, read our <a
                                href={ Constants.UserGuideUrl }
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-ZBF_green hover:underline cursor-pointer">Token Minting Guide</a>.</span>
                        <div className={ `${ blurBackground(appState) ? "blur-sm" : "" } mx-10 px-64 py-10` }>
                            { creatorTabs() }
                            { creatorContent() }
                        </div>
                    </div>
                </main>
            </div>
    )
}

export default TokenCreator
import React, { useEffect, useState } from "react"
import { useQuery } from "react-query"
import { useNavigate, useParams } from "react-router-dom"
import TokenmintButton, { TokenmintButtonStyle } from "../components/Common/TokenmintButton"
import ListContainer from "../components/ListContainer"
import ListHeader from "../components/ListHeader"
import { getMyTokens, getSingleToken, getTransactionsByToken } from "../network/APIRepository"
import { SCAPI } from "../network/explorer_types/SCAPI"
import { URLProvider } from "../utils/URLProvider"
import { boxesSums, delay, getElapsedTime, minimumUnitsToFormattedString } from "../utils/Utils"
import DashboardButton, { DashboardButtonType } from "../components/DashboardButton";
import { TransactionJSON } from "../network/explorer_types/Transaction";
import { Constants } from "../utils/Constants";
import TokenDetailInfo from "../components/TokenDetailInfo";
import copyIcon from "../assets/copy_icon.svg"
import ReactTooltip from "react-tooltip";
import { FeatureFlag, featureFlags, FeatureFlagType } from "../utils/FeatureFlags";
import { AppState } from "../types/AppState";
import { shallowEqual, useSelector } from "react-redux";
import TokenMintSpinner from "../templates/TokenMintSpinner";

const TokenDetail: React.FC = () => {
    const params = useParams()
    const navigate = useNavigate()
    const {tokenId} = params
    const appState: AppState = useSelector(
            (state: AppState) => state,
            shallowEqual
    )
    const blockExplorerUrl = useSelector((state: AppState) => state.blockExplorerUrl)

    const [address, setAddress] = useState<string | undefined>(undefined)
    const [tokenOwned, setTokenOwned] = useState(false)

    const {
        data: token,
        error,
        isLoading
    } = useQuery<SCAPI.TokenBoxType | void>(["getToken", tokenId], () => getSingleToken(tokenId ?? ""), {enabled: address !== undefined})

    const {
        data: txs,
        error: txsError,
        isLoading: txsIsLoading
    } = useQuery<any>(["getTransactionsByToken", tokenId], () => getTransactionsByToken(tokenId as string, undefined), {enabled: token !== undefined})

    const {
        data: myTokens,
        error: myTokensError,
        isLoading: myTokensIsLoading
    } = useQuery<any>(["getMyTokens", address, SCAPI.TokenType.Fungible], () => getMyTokens(SCAPI.TokenType.Fungible), {
        enabled: address !== undefined
    })

    //////////////////// Effects //////////////////////

    useEffect(() => {
        if (appState.walletState.walletAddress) {
            setAddress(appState.walletState.walletAddress)
        }
    }, [appState])

    useEffect(() => {
        if (myTokens && myTokens.find((token: SCAPI.TokenBoxType) => token.uuid === tokenId)) {
            setTokenOwned(true)
        }
    }, [myTokens])

    //////////////////////////////////////////////////////

    const amountFormatted = (tx: SCAPI.Transaction & TransactionJSON) => {
        if (!token) {
            return
        }
        const sumInfo = boxesSums(tx, token.symbol)
        const sum = sumInfo.output
        const amount = minimumUnitsToFormattedString(sum, token.precision)

        return (
                <td className="text-sm pr-8 text-white truncate" title={ amount.concat(" " + sumInfo.symbol) }>
                    <span className="inline">{ amount } </span>
                    <span
                            className="inline font-bold">{ sumInfo.symbol }</span>
                </td>
        )
    }

    const onMintTokenClick = () => {
        navigate(URLProvider.URL_TOKEN_MINT, {state: {tokenUUID: tokenId}})
    }
    const onAirdropClick = () => {
    }
    const onBurnTokenClick = () => {
        navigate(URLProvider.URL_TOKEN_BURN, {state: {tokenUUID: tokenId}})
    }

    const tokenMintFeatureFlag: FeatureFlag | undefined = featureFlags().find((item) => item.flag === FeatureFlagType.TOKENS_BUTTON_MINT)
    const tokenBurnFeatureFlag: FeatureFlag | undefined = featureFlags().find((item) => item.flag === FeatureFlagType.TOKENS_BUTTON_BURN)
    const tokenAirdropFeatureFlag: FeatureFlag | undefined = featureFlags().find((item) => item.flag === FeatureFlagType.TOKENS_BUTTON_AIRDROP)

    return (
            <div>
                <main>
                    <div
                            className="max-w-screen-xl ml-auto mr-auto xl:grid">

                        { !address && (
                                <p className="text-center text-Gray_text text-xl mt-40 mb-4">
                                    Connect your wallet to watch your token transactions<br/>
                                    or watch it on{ " " }
                                    <a className="text-ZBF_green hover:underline"
                                       target="_blank"
                                       href={ process.env.REACT_APP_MAINCHAIN_URL!.concat(`/token/${ tokenId }`) }>our
                                        block explorer</a>
                                </p>
                        ) }

                        { isLoading && (
                                <div className="w-14 mt-20 mx-auto">
                                    <TokenMintSpinner/>
                                </div>
                        ) }

                        { token && (
                                <div className="px-8 grid mt-12">
                                    <span className="text-white text-4xl font-bold">{ token.name }</span>
                                    <span
                                            className="text-Gray_text text-2xl uppercase font-bold mt-1">{ token.symbol }</span>
                                    { token.metadata && token.metadata["description"] && (
                                            <span className="text-white text-base mt-5">{ token.metadata["description"] }</span>
                                    ) }
                                    <span
                                            className="text-white text-Gray_text text-sm uppercase font-bold tracking-[0.2em] mt-5">Unique Id</span>
                                    <div className="flex">
                                        <span className="text-white text-base pt-1">{ token.uuid }</span>
                                        <button
                                                data-event="click"
                                                className="ml-2 p-1 rounded border border-transparent hover:border-ZBF_green">
                                            <img
                                                    data-tip="Token UUID copied to the pasteboard!"
                                                    data-for="description"
                                                    className="w-4 h-4"
                                                    src={ copyIcon }/>
                                            <ReactTooltip
                                                    id="description"
                                                    effect="solid"
                                                    multiline
                                                    event="click"
                                                    afterShow={ async () => {
                                                        navigator.clipboard.writeText(token.uuid)
                                                        await delay(2000)
                                                        ReactTooltip.hide()
                                                    } }
                                            />
                                        </button>
                                    </div>
                                </div>
                        ) }

                        { token && (
                                <div className="px-8 mt-10">
                                    <TokenDetailInfo token={ token }/>
                                </div>
                        ) }

                        { !isLoading && token && (tokenMintFeatureFlag?.enabled || tokenAirdropFeatureFlag?.enabled || tokenBurnFeatureFlag?.enabled) && (
                                <div className="px-8 grid-cols-4 grid space-x-6 mt-10">
                                    { tokenMintFeatureFlag?.enabled && tokenOwned && (
                                            <DashboardButton type={ DashboardButtonType.MINT_TOKEN }
                                                             onClick={ onMintTokenClick }/>
                                    ) }
                                    { tokenAirdropFeatureFlag?.enabled && tokenOwned && (
                                            <DashboardButton type={ DashboardButtonType.AIRDROP }
                                                             onClick={ onAirdropClick }/>
                                    ) }
                                    { tokenBurnFeatureFlag?.enabled && tokenOwned && (
                                            <DashboardButton type={ DashboardButtonType.BURN_TOKEN }
                                                             onClick={ onBurnTokenClick }/>
                                    ) }
                                </div>
                        ) }

                        { txsIsLoading && (
                                <div className="w-14 mx-auto">
                                    <TokenMintSpinner/>
                                </div>
                        ) }

                        { token && (
                                <div className="px-8 sm:pb-8 space-y-16 mt-20">
                                    <ListContainer
                                            titleSection={ <ListHeader title={ `My ${ token.name } transactions` }/> }
                                            elements={ ["Transaction Id", "Age", "Type Name", "Amount", "Fee"] }
                                            elementsConfig={ ["1/5", "1/5", "1/5", "1/5", "1/5"] }
                                            isLoading={ txsIsLoading }
                                            isEmpty={ txs ? txs[0].length === 0 : true }
                                            tableContent={
                                                txs ? txs[0].map((tx: SCAPI.Transaction & TransactionJSON, index: number) => {
                                                    const fontSize = "text-sm"
                                                    return (
                                                            <tr key={ tx.txid }
                                                                className={ (index === txs[0].length - 1 ? "" : "border-b")
                                                                        .concat(" border-Gray_text/20 h-20 list_container")
                                                                        .concat(` hover:bg-Hover_bckgrnd`) }>
                                                                <td className={ `${ fontSize } pl-4 pr-8 relative list_element element_left truncate` }>
                                                                    <a
                                                                            className="hover:underline text-ZBF_green"
                                                                            target="_blank"
                                                                            href={ blockExplorerUrl!.concat("/tx/").concat(tx.txid) }
                                                                    >
                                                                        { tx.txid }
                                                                    </a>
                                                                </td>


                                                                <td className={ `${ fontSize } pr-8 text-white whitespace-nowrap truncate` }>{ getElapsedTime(tx.blockTime!) }</td>
                                                                <td className={ `${ fontSize } pr-8 text-white truncate` }>{ tx.typeName }</td>
                                                                { amountFormatted(tx) }
                                                                <td className={ `${ fontSize } pr-4 text-right text-white truncate relative list_element element_right` }>
                                                <span
                                                        className="inline">{ tx.fee || tx.fee === 0
                                                        ? minimumUnitsToFormattedString(tx.fee, Constants.ZENPrecision)
                                                        : "-" }
                                                </span>
                                                                    <span className="inline font-bold"> ZEN</span>
                                                                </td>
                                                            </tr>
                                                    )
                                                }) : <div/>
                                            }
                                    >
                                    </ListContainer>
                                </div>
                        ) }

                        { txs && txs[0].length > 10 && (
                                <div className="mb-20 flex">
                                    <TokenmintButton
                                            title={ `View all` }
                                            style={ TokenmintButtonStyle.GREEN_BORDERED }
                                            enabled={ true }
                                            link={ blockExplorerUrl!.concat(`/address/${ address }`) }
                                    />
                                </div>
                        ) }
                    </div>
                </main>
            </div>
    )
}

export default TokenDetail
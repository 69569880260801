import React, { useEffect, useRef, useState } from "react"
import { useQuery } from "react-query"
import ReactSwitch from "react-switch"
import arrowCollapse from "../assets/arrow_collapse.png"
import { getTxFees } from "../network/APIRepository"
import { formatNumberString, minimumUnitsToFormattedString, quantityIsValid, toMinimumUnits } from "../utils/Utils"
import TokenmintButton, { TokenmintButtonStyle } from "./Common/TokenmintButton"
import FormCheckbox from "./Form/FormCheckbox"
import FormInput, { FormInputStyle } from "./Form/FormInput"
import TokenMintSpinner from "../templates/TokenMintSpinner";
import { amountFormatIsCorrect } from "./TokenCreatorMintInfo"

export type TokenCreatorTokenInfoProps = {
    name: string | undefined
    setName: (name: string) => void
    symbol: string | undefined
    setSymbol: (symbol: string) => void
    //without decimals, in minimum units
    supply: string | undefined
    setSupply: (supply: string) => void
    termsAndConditions: boolean
    setTermsAndConditions: (termsAndConditions: boolean) => void

    burnable: boolean
    onBurnableChange: () => void
    mintable: boolean
    onMintableChange: () => void
    unlimitedSupply: boolean
    onUnlimitedSupplyChange: () => void
    customizableDecimals: boolean
    onCustomizableDecimalsChange: () => void
    customizableDecimalsValue: number
    onCustomizableDecimalsValueChange: (value: number) => void

    onSubmit: () => void
}

export const tokenCreationFormIsValid = (name: string | undefined, symbol: string | undefined, supply: string | undefined, customizableDecimalsValue: number, termsAndConditions: boolean) => {
    const amountRegex = new RegExp("^[0-9]{1,78}$")
    const symbolRegex = new RegExp("[A-Za-z0-9]{3,6}")
    return name !== undefined && name !== ""
            && symbol !== undefined && symbol !== "" && symbolRegex.test(symbol)
            && supply !== undefined && supply !== "" && amountRegex.test(supply) 
            && quantityIsValid(supply) && amountFormatIsCorrect(supply) && termsAndConditions
}

const TokenCreatorTokenInfo: React.FC<TokenCreatorTokenInfoProps> = (
        {
            name,
            setName,
            symbol,
            setSymbol,
            supply,
            setSupply,
            termsAndConditions,
            setTermsAndConditions,
            burnable,
            onBurnableChange,
            mintable,
            onMintableChange,
            unlimitedSupply,
            onUnlimitedSupplyChange,
            customizableDecimals,
            onCustomizableDecimalsChange,
            customizableDecimalsValue,
            onCustomizableDecimalsValueChange,
            onSubmit
        }
) => {

    const maxAllowedDecimals = 18
    const ref = useRef<any>(null)
    const [validForm, setValidForm] = useState(false)
    const [advancedOpen, setAdvancedOpen] = useState(false)
    const [decimalsOffset, setDecimalsOffset] = useState(0)
    //with decimals
    const [supplyFormatted, setSupplyFormatted] = useState<string | undefined>(undefined)
    const [supplyError, setSupplyError] = useState<string | undefined>(undefined)

    const {
        data: fees,
        error: feeError,
        isLoading: feesIsLoading
    } = useQuery(['getTxFee'], () => getTxFees(), {retry: false})

    const checkSupplyForErrors = (supply:string) : string => {
        if(supply.includes(".")){
            return "Exceeds decimal precision"
        }
        if(!amountFormatIsCorrect(supply)){
            return "Format is invalid"
        }
        if(!quantityIsValid(supply)){
            return "Exceeds max limit"
        }
        return ""
    }

    //////////////////// Effects //////////////////////

    useEffect(() => {
        setDecimalsOffset((customizableDecimalsValue / maxAllowedDecimals) * (ref.current?.offsetWidth ? ref.current?.offsetWidth : 0) - 1 - (customizableDecimalsValue * 1.38))
    })

    useEffect(() => {
        if (feesIsLoading) {
            setValidForm(false)
            return
        }

        const validForm = tokenCreationFormIsValid(name, symbol, supply, customizableDecimalsValue, termsAndConditions)
        setValidForm(validForm)
    }, [name, symbol, supply, fees, feeError, customizableDecimalsValue, termsAndConditions])

    useEffect(() => {
        if (supply) {
            setSupplyError(checkSupplyForErrors(supply))
        }

    }, [supply, customizableDecimalsValue])

    //////////////////////////////////////////////////////

    return (
            <div className="grid mt-16">
                <div className="grid space-y-4">
                    <FormInput title="Token name" titleTooltip="The token full name" value={ name }
                               onChange={ setName }/>
                    <FormInput title="Token symbol"
                               titleTooltip={ <p>The symbol needs to be 3 to 6<br/>characters long and include only<br/>letters
                                   and numbers</p> }
                               value={ symbol } onChange={ (value) => {
                        const newValue = value.replace(/[^A-Za-z0-9]/g, '').substring(0, 6)
                        setSymbol(newValue)
                    } }/>
                    <FormInput title="Maximum Supply"
                               titleTooltip="The amount of tokens"
                               disabled={ unlimitedSupply }
                               value={ unlimitedSupply ? "Unlimited" : supplyFormatted }
                               error={ supplyError }
                               onChange={(value)=>{

                                const newValue = value.replace(/[^0-9.]/g, "")
                                setSupplyFormatted(newValue)
                                setSupply(toMinimumUnits(newValue, customizableDecimalsValue))
                            }}
                            onFocusLost={()=>{
                                setSupplyFormatted(formatNumberString(supplyFormatted))
                            }}
                            onFocus={()=>{
                                const simpleSupply = supplyFormatted ? supplyFormatted.replace(/[^0-9.]/g, "") : undefined 
                                setSupplyFormatted(simpleSupply)
                            }}
                               
                               />
                    { feesIsLoading &&
                            <div className="w-10 mx-auto">
                                <TokenMintSpinner/>
                            </div>
                    }
                    { fees &&
                            <FormInput title="Token creation fee"
                                       titleTooltip={ <p>The required fee for a token<br/>declaration transaction</p> }
                                       value={ fees.tokenDeclareFee.toString() }
                                       style={ FormInputStyle.BLACK }
                                       onChange={ () => {
                                       } }
                                       disabled={ true }/>
                    }
                </div>
                <button
                        className="filters_menu_button flex mx-auto my-6 text-white text-center"
                        onClick={ () => setAdvancedOpen(!advancedOpen) }>
            <span
                    className="w-24 tracking-[0.2em] mr-6 font-bold uppercase text-white font-bold text-base">Advanced</span>
                    <img
                            id="filter_arrow"
                            className={ `${ advancedOpen ? "open" : "" } h-[30%] my-auto pb-[0.5px]` }
                            src={ arrowCollapse }/>
                </button>
                { advancedOpen && (
                        <div className="grid-rows-4 space-y-6">
                            <FormCheckbox title="Mintable" value={ mintable } disabled={ true }
                                          onChange={ onMintableChange }
                                          titleTooltip={ <p>The ability to mint new tokens<br/> after the initial
                                              supply</p> }
                                          tooltip={ <p>This feature currently can’t be<br/>disabled. More
                                              flexibility
                                              will<br/>come with a later version</p> }/>
                            <FormCheckbox title="Burnable" value={ burnable } disabled={ true }
                                          onChange={ onBurnableChange }
                                          titleTooltip={ <p>The ability to destroy a part of<br/>the supply, e.g. to
                                              manage<br/>scarcity</p> }
                                          tooltip={ <p>This feature currently can’t be<br/>disabled. More
                                              flexibility
                                              will<br/>come with a later version</p> }/>
                            <FormCheckbox title="Unlimited supply" value={ unlimitedSupply } disabled={ false }
                                          titleTooltip={ <p>Minting can be done unlimited<br/>number of times</p> }
                                          onChange={ onUnlimitedSupplyChange }/>
                            <FormCheckbox title="Customizable decimals" value={ customizableDecimals }
                                          disabled={ false }
                                          titleTooltip={ <p>Choose the number of decimals<br/>of your token. If
                                              disabled the<br/>decimals
                                              are 6 by default</p> }
                                          onChange={ onCustomizableDecimalsChange }/>
                        </div>
                ) }
                { advancedOpen && customizableDecimals && (
                        <div className="grid mt-4" ref={ ref }>
                            <div className="block mt-2 mb-1 text-white">
                    <span
                            className={ `${ customizableDecimalsValue === 0 ? "text-white font-bold" : "text-Gray_text font-normal" } text-sm ml-[0.1rem] pb-2` }>0</span>
                                <span className="relative font-bold text-sm pb-2"
                                      style={ {left: `${ decimalsOffset }px`} }>{ customizableDecimalsValue === 0 || customizableDecimalsValue === maxAllowedDecimals ? "" : customizableDecimalsValue }</span>
                                <span
                                        className={ `${ customizableDecimalsValue === maxAllowedDecimals ? "text-white font-bold" : "text-Gray_text font-normal" } text-sm pt-[2px] float-right` }>{ maxAllowedDecimals }</span>
                            </div>
                            <input
                                    className="mt-1"
                                    style={ {background: `linear-gradient(to right, #26DB8D ${ decimalsOffset + 10 }px, #fff ${ decimalsOffset + 10 }px, #fff 100%)`} }
                                    max={ maxAllowedDecimals } value={ customizableDecimalsValue }
                                    onChange={ (event) => {
                                        onCustomizableDecimalsValueChange(event.target.valueAsNumber)
                                    } } type="range"/>
                        </div>
                ) }
                <div className="grid mt-12">
            <span
                    className="text-base tracking-[0.2em] uppercase text-Gray_text font-bold">Terms and conditions</span>
                    <div className="block mt-2">
                <span className="text-base text-white mr-4 pt-[1px]">I agree to the <a
                        className="text-ZBF_green hover:underline"
                        href={ process.env.REACT_APP_TOKENMINT_URL!.concat("/terms") }>Terms and Conditions</a></span>
                        <ReactSwitch className={ `${ termsAndConditions ? "active" : "inactive" } float-right` }
                                     checked={ termsAndConditions } disabled={ false }
                                     onChange={ () => setTermsAndConditions(!termsAndConditions) }
                                     onColor={ "#26DB8D" } offColor={ "#7A7E8C" }
                                     height={ 10 } width={ 26 } handleDiameter={ 28 }
                                     checkedIcon={ false }
                                     uncheckedIcon={ false }/>
                    </div>
                </div>
                <TokenmintButton
                        title="Confirm"
                        style={ TokenmintButtonStyle.GREEN_BORDERED }
                        enabled={ validForm }
                        additionalClasses="mt-14"
                        onClick={ onSubmit }>
                </TokenmintButton>
            </div>
    )
}

export default TokenCreatorTokenInfo
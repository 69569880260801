import React, { Dispatch, useEffect, useState } from "react"
import { amountIsValid } from "../components/TokenCreatorMintInfo"
import { WalletStatus } from "../types/WalletState";
import BigNumber from "bignumber.js";
import { getBalanceByAddress, getMyTokens } from "../network/APIRepository";
import { minimumUnitsToFormattedString } from "../utils/Utils";
import { AppState, blurBackground } from "../types/AppState";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { WALLET_NOT_CONNECTED_CLICK } from "../store/actionTypes";
import { useLocation, useNavigate } from "react-router-dom";
import FormInput from "../components/Form/FormInput";
import TokenmintButton, { TokenmintButtonStyle } from "../components/Common/TokenmintButton";
import FormDropdown from "../components/Form/FormDropdown";
import { useQuery } from "react-query";
import { Balance } from "../network/explorer_types/TokenchainAPI";
import { SCAPI } from "../network/explorer_types/SCAPI";
import TokenMintSpinner from "../templates/TokenMintSpinner";

export const tokenBurnFormIsValid = (amount: string | undefined, currentSupply: BigNumber, maxSupply: BigNumber, precision: number): { isValid: boolean, error: string | undefined } => {
    return amountIsValid(amount, currentSupply, maxSupply, precision, false)
}

const TokenBurn: React.FC = (props: any) => {
    const location = useLocation();
    const navigate = useNavigate()

    const appState: AppState = useSelector(
            (state: AppState) => state,
            shallowEqual
    )

    const dispatch: Dispatch<any> = useDispatch()

    const [tokenUUID, setTokenUUID] = useState<string | undefined>(undefined)
    const [currentToken, setCurrentToken] = useState<SCAPI.TokenBoxType | undefined>(undefined)

    // TokenInfo
    const [newSupply, setNewSupply] = useState<string | undefined>(undefined)
    const [supply, setSupply] = useState<string | undefined>(undefined)

    // TokenBurn
    const [validForm, setValidForm] = useState(false)
    const [amountFormatted, setAmountFormatted] = useState<string | undefined>(undefined)
    const [walletAddress, setWalletAddress] = useState<string | undefined>(undefined)
    const [amount, setAmount] = useState<string | undefined>(undefined)
    const [amountError, setAmountError] = useState<string | undefined>(undefined)

    const {
        data: myTokensBalance,
        error: myTokensBalanceError,
        isLoading: myTokensBalanceIsLoading
    } = useQuery<any>(["getBalanceByAddress", walletAddress], () => getBalanceByAddress(), {
        enabled: walletAddress !== undefined
    })

    const {
        data: myTokens,
        error: myTokensError,
        isLoading: myTokensIsLoading
    } = useQuery<any>(["getMyTokens", walletAddress, SCAPI.TokenType.Fungible], () => getMyTokens(SCAPI.TokenType.Fungible), {
        enabled: walletAddress !== undefined
    })

    //////////////////// Effects //////////////////////

    // Gets address from appState
    useEffect(() => {
        if (appState.walletState.walletAddress) {
            setWalletAddress(appState.walletState.walletAddress)
        }
    }, [appState])

    // Detects UUID as a parameter in case we're navigating from tokenDetail screen
    useEffect(() => {
        if (location.state && (location.state as any)["tokenUUID"]) {
            setTokenUUID((location.state as any)["tokenUUID"])
        }
    }, [])

    useEffect(() => {
        if (myTokens && myTokens.length > 0) {
            if (!tokenUUID) {
                // Sets the first token as the selected one. This way it will appear as default in the dropdown selector
                setCurrentToken(myTokens[0])
            } else {
                // If the tokenUUID is known (navigating from token detail), it will show its info
                const token = myTokens.find((token: SCAPI.TokenBoxType) => token.uuid === tokenUUID)
                setCurrentToken(token)
            }
        }
    }, [myTokens])

    // On new token selected, stores it
    useEffect(() => {
        if (myTokens) {
            const token = myTokens.find((token: SCAPI.TokenBoxType) => token.uuid === tokenUUID)
            setCurrentToken(token)
        }
    }, [tokenUUID])

    // On token selected change, finds the supply to show
    useEffect(() => {
        if (currentToken) {
            setAmountError(undefined)
            setSupply(currentToken.currentSupply as any)
        }
    }, [currentToken])

    // Checks the validity of the amount inserted
    useEffect(() => {
        if (!currentToken) {
            setNewSupply("")
            setValidForm(false)
            setAmountError(undefined)
            return
        }

        setAmountFormatted(minimumUnitsToFormattedString(amount, currentToken.precision, true))

        const amountValid = amountIsValid(amount, new BigNumber(currentToken!.currentSupply as any), new BigNumber(currentToken!.maxSupply as any), currentToken.precision, false)
        setValidForm(amountValid.isValid)
        setAmountError(amountValid.error)
        amountValid.isValid
                ? setNewSupply(minimumUnitsToFormattedString(new BigNumber(currentToken!.currentSupply as any).minus(new BigNumber(amount!)), currentToken.precision))
                : setNewSupply(minimumUnitsToFormattedString(new BigNumber(currentToken!.currentSupply as any), currentToken.precision))
    }, [amount])

    //////////////////////////////////////////////////////

    const onSubmit = () => {
        if (appState.walletState.walletStatus !== WalletStatus.CONNECTED) {
            dispatch({
                type: WALLET_NOT_CONNECTED_CLICK
            })
            return
        }

        if (!currentToken) {
            return
        }

        if (!tokenBurnFormIsValid(amount, new BigNumber(supply!), new BigNumber(currentToken!.maxSupply as any), currentToken.precision)) {
            return
        }

        // TODO: Add burnToken wallet request

        // sendTokenMint(
        //         "",
        //         currentToken!.uuid,
        //         amount!
        // ).then(async (resp) => {
        //     dispatch({
        //         type: TOKEN_BURN_COMPLETED
        //     })
        //
        //     await delay(3000)
        //
        //     dispatch({
        //         type: CLOSE_MODALS
        //     })
        //
        //     navigate(URLProvider.URL_HOME)
        //
        // }).catch((error) => {
        //     if (error.message.includes("closed")) {
        //         dispatch({
        //             type: TOKEN_BURN_MODAL_CLOSED
        //         })
        //     }
        //     if (error.message.includes("rejected")) {
        //         dispatch({
        //             type: CLOSE_MODALS
        //         })
        //     }
        // })
        //
        // dispatch({
        //     type: TOKEN_BURN_STARTED
        // })
    }

    return (
            <div>
                <main>
                    <div
                            className="max-w-screen-xl ml-auto mr-auto grid">
                    <span
                            className={ `${ blurBackground(appState) ? "blur-sm" : "" } mx-auto text-4xl font-bold text-white text-center mt-24` }>Burn tokens</span>
                        <span
                                className={ `${ blurBackground(appState) ? "blur-sm" : "" } mx-auto text-base text-Content_gray text-center mt-8 px-80` }>Burn tokens to reduce supply of your token. <br/>Read our <a
                                className="text-ZBF_green hover:underline cursor-pointer">Token Burning Guide</a> to learn more about this process</span>
                        <div className={ `${ blurBackground(appState) ? "blur-sm" : "" } mx-10 px-64 py-10` }>
                            <div className="grid mt-16">
                                <div className="grid space-y-4">
                                    { !currentToken && myTokensIsLoading && (
                                            <div className="w-10 mx-auto">
                                                <TokenMintSpinner/>
                                            </div>
                                    ) }
                                    { currentToken && myTokensBalance && (
                                            <FormDropdown title="Select token"
                                                          rightText={ `Your balance: ${ minimumUnitsToFormattedString(myTokensBalance.find((balance: Balance) => balance.uuid === currentToken!.uuid)?.balance ?? "0", currentToken!.precision) }` }
                                                          allValues={ myTokens }
                                                          value={ tokenUUID }
                                                          onChange={ setTokenUUID }/>
                                    ) }
                                    <FormInput title="Quantity to burn"
                                               titleTooltip={ <p>The amount of tokens that will<br/>be burned and
                                                   removed from<br/>circulation</p> }
                                               value={ amountFormatted }
                                               error={ amountError }
                                               onChange={ (value) => {
                                                   const newValue = value.replace(/\D/g, '')
                                                   setAmount(newValue)
                                               } }/>
                                    <div className="flex">
                                        { currentToken && (
                                                <div className="w-full">
                                                    <FormInput title="Current supply"
                                                               value={ minimumUnitsToFormattedString(supply, currentToken.precision) }
                                                               disabled
                                                               onChange={ (value) => setSupply }/>
                                                </div>
                                        ) }
                                        { currentToken && (
                                                <div className="w-full">
                                                    <FormInput title="New supply"
                                                               value={ newSupply }
                                                               disabled
                                                               onChange={ (value) => {
                                                               } }/>
                                                </div>
                                        ) }
                                    </div>

                                </div>

                                <div className="flex mx-auto mt-14">
                                    <TokenmintButton
                                            title="Confirm"
                                            style={ TokenmintButtonStyle.GREEN_BORDERED }
                                            enabled={ validForm }
                                            onClick={ onSubmit }>
                                    </TokenmintButton>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
    )
}

export default TokenBurn
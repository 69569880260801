import TokenMintSpinner from "../templates/TokenMintSpinner"
import React from "react"
import { AppState } from "../types/AppState"
import { shallowEqual, useSelector } from "react-redux"

export type PendingTokenMessageProps = {
    transactionId?: string | undefined
    tokenType?: "nft" | "token"
}

const PendingTokenMessage: React.FC<PendingTokenMessageProps> = ({ transactionId, tokenType = "token" }) => {
    const appState: AppState = useSelector((state: AppState) => state, shallowEqual)
    const blockExplorerUrl = useSelector((state: AppState) => state.blockExplorerUrl)

    const spinner = (
        <div className="w-12 mx-6 my-auto">
            <TokenMintSpinner />
        </div>
    )

    if (tokenType === "token") {
        return (
            <div className="mx-auto mt-6 flex">
                {spinner}
                <p className="text-white text-base flex">
                    Your token is still being created. You will be able to mint your token once your create token
                    transaction is recorded in the next block.
                </p>
            </div>
        )
    } else {
        if (transactionId) {
            return (
                <div className="mx-auto mt-6 flex">
                    {spinner}
                    <p className="text-white text-base">
                        Your collection is still being created. You will be able to mint your NFTs once your create
                        collection transaction is recorded in the next block. Check the status{" "}
                        <a
                            className="text-ZBF_green hover:underline"
                            href={`${blockExplorerUrl!}/tx/${transactionId}`}
                            target="_blank"
                        >
                            here
                        </a>
                    </p>
                </div>
            )
        } else {
            return (
                <div className="mx-auto mt-6 flex">
                    {spinner}
                    <p className="text-white text-base flex">
                        Your collection is still being created. You will be able to mint your NFTs once your create
                        collection transaction is recorded in the next block.
                    </p>
                </div>
            )
        }
    }
}

export default PendingTokenMessage

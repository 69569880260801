import React, { Dispatch, useEffect, useState } from "react"
import { collectionMintFormIsValid } from "../components/CollectionCreatorMintInfo"
import { addressIsValid } from "../components/TokenCreatorMintInfo"
import { WalletStatus } from "../types/WalletState";
import { getAllCollections, getMyTokens, getTxFees, sendNFTMint } from "../network/APIRepository";
import { Constants } from "../utils/Constants";
import { delay } from "../utils/Utils";
import { AppState, blurBackground } from "../types/AppState";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
    CLOSE_MODALS,
    COLLECTION_DECLARATION_CONFIRMED,
    COLLECTION_MINT_COMPLETED,
    COLLECTION_MINT_STARTED,
    TOKEN_MINT_MODAL_CLOSED,
    WALLET_NOT_CONNECTED_CLICK
} from "../store/actionTypes";
import { URLProvider } from "../utils/URLProvider";
import { useLocation, useNavigate } from "react-router-dom";
import FormInput, { FormInputStyle } from "../components/Form/FormInput";
import TokenmintButton, { TokenmintButtonStyle } from "../components/Common/TokenmintButton";
import FormDropdown from "../components/Form/FormDropdown";
import { useQuery } from "react-query";
import { SCAPI } from "../network/explorer_types/SCAPI";
import TokenMintSpinner from "../templates/TokenMintSpinner";
import PendingTokenMessage from "../components/PendingTokenMessage";

const NFTMint: React.FC = (props: any) => {
    const location = useLocation();
    const navigate = useNavigate()

    const appState: AppState = useSelector(
            (state: AppState) => state,
            shallowEqual
    )

    const dispatch: Dispatch<any> = useDispatch()

    const [tokenUUID, setTokenUUID] = useState<string | undefined>(undefined)
    const [currentNFT, setCurrentNFT] = useState<SCAPI.TokenBoxType | undefined>(undefined)

    // TokenMint
    const [validForm, setValidForm] = useState(false)
    const [walletAddress, setWalletAddress] = useState<string | undefined>(undefined)
    const [address, setAddress] = useState<string | undefined>(undefined)
    const [addressError, setAddressError] = useState<string | undefined>(undefined)
    const [pendingTokenUUID, setPendingTokenUUID] = useState<string | undefined>(undefined)

    const {
        data: myCollections,
        error: myCollectionsError,
        isLoading: myCollectionsIsLoading
    } = useQuery<any>(["getMyTokens", walletAddress, SCAPI.TokenType.NonFungible], () => getMyTokens(SCAPI.TokenType.NonFungible), {
        enabled: walletAddress !== undefined
    })

    const {
        data: fees,
        error: feeError,
        isLoading: feesIsLoading
    } = useQuery(['getTxFee'], () => getTxFees(), {retry: false})

    const {
        data: newlyCreatedToken,
    } = useQuery<Array<SCAPI.TokenBoxType> | void>(["getCollection", pendingTokenUUID], () => getAllCollections([pendingTokenUUID ?? ""]), {
        enabled: pendingTokenUUID !== undefined,
        refetchInterval: 20000,
        onSuccess: ((resp) => {
            if (resp && resp.length > 0) {
                setPendingTokenUUID(undefined)
                dispatch({
                    type: COLLECTION_DECLARATION_CONFIRMED
                })
            }
        })
    })

    //////////////////// Effects //////////////////////

    // Gets address from appState - We use 2 variables as the address form input is editable
    useEffect(() => {
        if (appState.walletState.walletAddress) {
            setWalletAddress(appState.walletState.walletAddress)
            setAddress(appState.walletState.walletAddress)
        }
    }, [appState])

    // Detects UUID as a parameter in case we're navigating from tokenDetail screen
    useEffect(() => {
        if (location.state && (location.state as any)["tokenUUID"]) {
            setTokenUUID((location.state as any)["tokenUUID"])
        }
    }, [])

    useEffect(() => {
        if (myCollections && myCollections.length > 0) {
            if (!tokenUUID) {
                // Sets the first token as the selected one. This way it will appear as default in the dropdown selector
                setCurrentNFT(myCollections[0])
            } else {
                // If the tokenUUID is known (navigating from token detail), it will show its info
                const token = myCollections.find((token: SCAPI.TokenBoxType) => token.uuid === tokenUUID)
                setCurrentNFT(token)
            }
        }
    }, [myCollections])

    // On new token selected, stores it
    useEffect(() => {
        if (myCollections) {
            const token = myCollections.find((token: SCAPI.TokenBoxType) => token.uuid === tokenUUID)
            setCurrentNFT(token)
        }
    }, [tokenUUID])

    useEffect(() => {
        if (currentNFT) {
            if (currentNFT.mintHeight === -1) {
                setPendingTokenUUID(currentNFT.uuid)
            } else {
                setPendingTokenUUID(undefined)
            }
        }
    }, [currentNFT])

    // Checks the validity of the address
    useEffect(() => {
        const addressValid = addressIsValid(address)
        if (!addressValid) {
            setValidForm(false)
        }
        setAddressError(addressValid ? undefined : "Invalid address")
    }, [address])

    useEffect(() => {
        if (!currentNFT) {
            setValidForm(false)
            return
        }

        if (feesIsLoading) {
            setValidForm(false)
            return;
        }

        setValidForm(pendingTokenUUID === undefined)
    }, [currentNFT, fees, feeError, pendingTokenUUID])

    //////////////////////////////////////////////////////

    const onSubmit = () => {
        if (appState.walletState.walletStatus !== WalletStatus.CONNECTED) {
            dispatch({
                type: WALLET_NOT_CONNECTED_CLICK
            })
            return
        }

        if (!currentNFT) {
            return
        }

        if (!collectionMintFormIsValid(address)) {
            return
        }

        sendNFTMint(
                address!,
                currentNFT!.uuid,
                Number(BigInt(currentNFT.currentSupply) + BigInt(1)),
        ).then(async (resp) => {
            dispatch({
                type: COLLECTION_MINT_COMPLETED
            })

            await delay(3000)

            dispatch({
                type: CLOSE_MODALS
            })

            navigate(URLProvider.URL_NFTs)

        }).catch((error) => {
            if (error.message.includes("closed")) {
                dispatch({
                    type: TOKEN_MINT_MODAL_CLOSED
                })
            }
            if (error.message.includes("rejected")) {
                dispatch({
                    type: CLOSE_MODALS
                })
            }
        })

        dispatch({
            type: COLLECTION_MINT_STARTED
        })
    }

    return (
            <div>
                <main>
                    <div
                            className="max-w-screen-xl ml-auto mr-auto grid">
                        <span
                                className={ `${ blurBackground(appState) ? "blur-sm" : "" } mx-auto text-4xl font-bold text-white text-center mt-24` }>Mint NFT</span>
                        <span
                                className={ `${ blurBackground(appState) ? "blur-sm" : "" } mx-auto text-base text-Content_gray text-center mt-8 px-80` }>Mint an NFT as part of your collection. <br/>Read our <a
                                href={ Constants.UserGuideUrl }
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-ZBF_green hover:underline cursor-pointer">NFT Minting Guide</a> to learn more about the process.</span>
                        <div className={ `${ blurBackground(appState) ? "blur-sm" : "" } mx-10 px-64 py-10` }>
                            <div className="grid mt-16">
                                <div className="grid space-y-4">
                                    { !currentNFT && myCollectionsIsLoading && (
                                            <div className="w-10 mx-auto">
                                                <TokenMintSpinner/>
                                            </div>
                                    ) }
                                    { currentNFT && (
                                            <FormDropdown title="Select collection"
                                                          allValues={ myCollections }
                                                          value={ currentNFT ? currentNFT.uuid : tokenUUID }
                                                          onChange={ setTokenUUID }/>
                                    ) }
                                    <FormInput title="Mint to address"
                                               value={ address }
                                               onChange={ setAddress }
                                               error={ addressError }
                                               titleTooltip={ <p>The address where the minted<br/>NFT will be
                                                   sent to</p> }/>
                                    { feesIsLoading && (
                                            <div className="w-10 mx-auto">
                                                <TokenMintSpinner/>
                                            </div>
                                    ) }
                                    { fees &&
                                            <FormInput title="NFT mint fee"
                                                       titleTooltip={ <p>The required fee for an NFT<br/>minting
                                                           transaction
                                                       </p> }
                                                       value={ fees.NFTMintFee.toString() }
                                                       style={ FormInputStyle.BLACK }
                                                       onChange={ () => {
                                                       } }
                                                       disabled={ true }/>
                                    }
                                </div>

                                { pendingTokenUUID && (
                                        <PendingTokenMessage tokenType="nft" transactionId={ pendingTokenUUID }/>
                                ) }

                                <div className="flex mx-auto mt-10">
                                    <TokenmintButton
                                            title="Mint NFT"
                                            style={ TokenmintButtonStyle.GREEN_BORDERED }
                                            enabled={ validForm }
                                            onClick={ onSubmit }>
                                    </TokenmintButton>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
    )
}

export default NFTMint
import React, { useEffect, useState } from "react"
import TokenmintButton, { TokenmintButtonStyle } from "./Common/TokenmintButton"
import FormInput, { FormInputStyle } from "./Form/FormInput"
import { AppState } from "../types/AppState";
import { shallowEqual, useSelector } from "react-redux";
import { useQuery } from "react-query";
import { getTxFees } from "../network/APIRepository";
import TokenMintSpinner from "../templates/TokenMintSpinner";
import { addressIsValid } from "./TokenCreatorMintInfo";
import PendingTokenMessage from "./PendingTokenMessage";

export type CollectionCreatorMintInfoProps = {
    tokenUUID: string | undefined
    pendingCollectionTransactionId: string | undefined
    address: string | undefined
    setAddress: (address: string) => void

    onSubmit: () => void
}

export const collectionMintFormIsValid = (address: string | undefined): { isValid: boolean, error: string | undefined } => {
    const addressCheck: boolean = addressIsValid(address)
    return {isValid: addressCheck, error: addressCheck ? undefined : "Address not valid"}
}

const CollectionCreatorMintInfo: React.FC<CollectionCreatorMintInfoProps> = (
        {
            tokenUUID,
            pendingCollectionTransactionId,
            address,
            setAddress,
            onSubmit
        }
) => {

    const appState: AppState = useSelector(
            (state: AppState) => state,
            shallowEqual
    )

    const [validForm, setValidForm] = useState(false)
    const [addressError, setAddressError] = useState<string | undefined>(undefined)

    const {
        data: fees,
        error: feeError,
        isLoading: feesIsLoading
    } = useQuery(['getTxFee'], () => getTxFees(), {retry: false})

    //////////////////// Effects //////////////////////

    useEffect(() => {
        if (feesIsLoading) {
            setValidForm(false)
            return
        }

        const tokenFormValid = collectionMintFormIsValid(address)
        setValidForm(tokenFormValid.isValid && !appState.walletState.pendingCollectionCreation)

        if (address) {
            setAddressError(addressIsValid(address) ? undefined : "Invalid format")
        }
    }, [address, fees, feeError, appState])

    //////////////////////////////////////////////////////

    return (
            <div className="grid mt-16">
                <div className="grid space-y-4">
                    <FormInput title="Mint to address" value={ address } onChange={ setAddress }
                               error={ addressError }
                               titleTooltip={ <p>The address where the minted<br/>NFTs will be sent to</p> }/>
                    { feesIsLoading &&
                            <div className="w-10 mx-auto">
                                <TokenMintSpinner/>
                            </div>
                    }
                    { fees &&
                            <FormInput title="NFT mint fee"
                                       titleTooltip={ <p>The required fee for a NFT<br/>minting transaction</p> }
                                       value={ fees.NFTMintFee?.toString() ?? 0 }
                                       style={ FormInputStyle.BLACK }
                                       onChange={ () => {
                                       } }
                                       disabled={ true }/>
                    }
                </div>

                { appState.walletState.pendingCollectionCreation && (
                        <PendingTokenMessage tokenType="nft" transactionId={ pendingCollectionTransactionId }/>
                ) }

                <div className="flex mx-auto w-[60%] mt-10">
                    <TokenmintButton
                            title="Mint NFT"
                            style={ TokenmintButtonStyle.GREEN_BORDERED }
                            enabled={ validForm }
                            onClick={ () => {
                                if (!tokenUUID) {
                                    setAddressError("There was an error. Try again from collection detail screen")
                                    return
                                }
                                onSubmit()
                            } }>
                    </TokenmintButton>
                </div>
            </div>
    )
}

export default CollectionCreatorMintInfo
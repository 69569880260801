import React, { useEffect, useState } from "react"
import { useQuery } from "react-query"
import { Link, useNavigate } from "react-router-dom"
import TokenmintButton, { TokenmintButtonStyle } from "../components/Common/TokenmintButton"
import ListContainer from "../components/ListContainer"
import ListHeader from "../components/ListHeader"
import { getAllTokens, getBalanceByAddress, getMyTokens, getNetworkId } from "../network/APIRepository"
import { Balance } from "../network/explorer_types/TokenchainAPI"
import { WalletStatus } from "../types/WalletState"
import { URLProvider } from "../utils/URLProvider"
import { delay, maxSupplyDisplay, minimumUnitsToFormattedString } from "../utils/Utils"
import DashboardButton, { DashboardButtonType } from "../components/DashboardButton";
import { AppState, blurBackground } from "../types/AppState";
import { shallowEqual, useSelector } from "react-redux";
import { FeatureFlag, featureFlags, FeatureFlagType } from "../utils/FeatureFlags";
import { SCAPI } from "../network/explorer_types/SCAPI";

const Tokens: React.FC = () => {

    const navigate = useNavigate()
    const appState: AppState = useSelector(
            (state: AppState) => state,
            shallowEqual
    )

    const blockExplorerUrl = useSelector((state: AppState) => state.blockExplorerUrl)

    const [address, setAddress] = useState<string | undefined>(undefined)
    const [walletConnecting, setWalletConnecting] = useState<boolean>(false)
    const [tokenOwned, setTokenOwned] = useState(false)

    const [isTestnet, setIsTestnet] = useState(false)

    const {
        data: myTokensBalance,
        error: myTokensBalanceError,
        isLoading: myTokensBalanceIsLoading
    } = useQuery<any>(["getBalanceByAddress", address], () => getBalanceByAddress(), {
        enabled: address !== undefined
    })

    const {
        data: myTokens,
        error: myTokensError,
        isLoading: myTokensIsLoading
    } = useQuery<any>(["getMyTokens", address, SCAPI.TokenType.Fungible], () => getMyTokens(SCAPI.TokenType.Fungible), {
        enabled: address !== undefined
    })

    const {
        data: allTokens,
        error: allTokensError,
        isLoading: allTokensIsLoading
    } = useQuery<any>("getAllTokens", () => getAllTokens(0, 10), {
        enabled: address !== undefined
    })

    //////////////////// Effects //////////////////////

    useEffect(() => {
        if (appState.walletState.walletAddress) {
            setAddress(appState.walletState.walletAddress)
        }
        setWalletConnecting(appState.walletState.walletStatus === WalletStatus.CONNECTING)
    }, [appState])

    useEffect(() => {
        if (myTokens && myTokens.length > 0) {
            setTokenOwned(true)
        }
    }, [myTokens])

    useEffect(() => {
        const updateTestnet = async () => {
            let walletNetworkId = await getNetworkId();
            setIsTestnet(walletNetworkId === "2" || walletNetworkId === "3")
        }
        updateTestnet()
    }, [])

    //////////////////////////////////////////////////////

    const onCreateTokenClick = () => {
        navigate(URLProvider.URL_TOKEN_CREATOR)
    }
    const onMintTokenClick = () => {
        navigate(URLProvider.URL_TOKEN_MINT)
    }
    const onAirdropClick = () => {
    }
    const onBurnTokenClick = () => {
        navigate(URLProvider.URL_TOKEN_BURN)
    }

    const onGetTZENClick = () => {
        window.open(URLProvider.URL_GET_TZEN, "_blank")
    }

    const mintButtonFeatureFlag: FeatureFlag | undefined = featureFlags().find((item) => item.flag === FeatureFlagType.TOKENS_BUTTON_MINT)
    const airdropButtonFeatureFlag: FeatureFlag | undefined = featureFlags().find((item) => item.flag === FeatureFlagType.TOKENS_BUTTON_AIRDROP)
    const burnButtonFeatureFlag: FeatureFlag | undefined = featureFlags().find((item) => item.flag === FeatureFlagType.TOKENS_BUTTON_BURN)
    const tZEN_FaucetFlag: FeatureFlag | undefined = featureFlags().find((item) => item.flag === FeatureFlagType.TZEN_FAUCET)

    return (
            <div>
                <main>
                    <div className={ `${ blurBackground(appState) ? "blur-sm" : "" } max-w-screen-xl ml-auto mr-auto xl:grid` }>
                        { appState.walletState.walletStatus === WalletStatus.NOT_CONNECTED && (
                                <div className="px-8 sm:pb-8 mt-20">
                                    <ListHeader title="Connect wallet to get started"/>
                                    <p className="text-base text-Gray_text">
                                        If you don’t have the Cobalt Wallet extension you can download it <a
                                            className="text-ZBF_green hover:underline"
                                            href={ process.env.REACT_APP_WALLET_INSTALL! }>here.</a>
                                    </p>
                                </div>
                        ) }

                        { appState.walletState.walletStatus === WalletStatus.CONNECTED && (
                                <div className="px-8 grid-cols-4 lg:grid xl:grid flex space-x-6 mt-12">
                                    <DashboardButton type={ DashboardButtonType.CREATE_TOKEN }
                                                     onClick={ onCreateTokenClick }/>
                                    { mintButtonFeatureFlag?.enabled && tokenOwned && (
                                            <DashboardButton type={ DashboardButtonType.MINT_TOKEN }
                                                             onClick={ onMintTokenClick }/>
                                    ) }
                                    { isTestnet && tZEN_FaucetFlag?.enabled && (
                                            <DashboardButton type={ DashboardButtonType.GET_TZEN }
                                                             onClick={ onGetTZENClick }/>
                                    ) }
                                    { airdropButtonFeatureFlag?.enabled && tokenOwned && (
                                            <DashboardButton type={ DashboardButtonType.AIRDROP }
                                                             onClick={ onAirdropClick }/>
                                    ) }
                                    { burnButtonFeatureFlag?.enabled && tokenOwned && (
                                            <DashboardButton type={ DashboardButtonType.BURN_TOKEN }
                                                             onClick={ onBurnTokenClick }/>
                                    ) }
                                </div>
                        ) }

                        <div className="px-8 sm:pb-8 space-y-16 mt-20">
                            <ListContainer
                                    titleSection={ <ListHeader title="Your tokens"/> }
                                    elements={ ["Token", "Symbol", "Circulating Supply", "Maximum Supply", "Balance", "UUID"] }
                                    elementsConfig={ ["1/6", "1/6", "1/6", "1/6", "1/6", "1/6"] }
                                    isLoading={ myTokensBalanceIsLoading || myTokensIsLoading || walletConnecting }
                                    isEmpty={ appState.walletState.walletStatus === WalletStatus.CONNECTED && myTokensBalance && myTokens ? myTokens.length === 0 && !myTokensIsLoading : !walletConnecting }
                                    customEmptyMessage={ appState.walletState.walletStatus === WalletStatus.NOT_CONNECTED ? "Connect your wallet to see your tokens." : "No token has been created yet" }
                                    tableContent={
                                        (myTokensBalance && myTokens && myTokens.length > 0) ? myTokens.reduce((uniqueTokens: Array<SCAPI.TokenBoxType>, token: SCAPI.TokenBoxType) => (uniqueTokens.find(t => t.uuid === token.uuid) ? uniqueTokens : [...uniqueTokens, token]), []).slice(0, 10).map((token: SCAPI.TokenBoxType, index: number) => {
                                            const balanceInfo = myTokensBalance.find((b: Balance) => token.uuid === b.uuid)
                                            const currentSupplyFormatted = minimumUnitsToFormattedString(token.currentSupply, token.precision)
                                            const maxSupplyFormatted = maxSupplyDisplay(token.maxSupply, token.precision)
                                            const balanceFormatted = balanceInfo ? minimumUnitsToFormattedString(balanceInfo.balance, token.precision) : undefined
                                            const fontSize = "text-base"
                                            return (
                                                    <tr key={ token.uuid }
                                                        className={ (index === myTokensBalance.length - 1 ? "" : "border-b")
                                                                .concat(" border-Gray_text/20 h-20 list_container")
                                                                .concat(` hover:bg-Hover_bckgrnd`) }>
                                                        <td className={ `${ fontSize } pl-4 pr-8 relative list_element element_left text-white truncate font-bold` }
                                                            title={ token?.name }>
                                                            { token?.name }
                                                        </td>

                                                        <td className={ `${ fontSize } pr-8 text-white truncate` }>{ token.symbol }</td>
                                                        <td className={ `${ fontSize } pr-8 text-white truncate` }
                                                            title={ currentSupplyFormatted }>{ currentSupplyFormatted }</td>
                                                        <td className={ `${ fontSize } pr-8 text-white truncate` }
                                                            title={ maxSupplyFormatted }>{ maxSupplyFormatted }
                                                        </td>
                                                        <td className={ `${ fontSize } pr-8 text-white truncate` }
                                                            title={ balanceFormatted }>{ balanceFormatted ?? 0 }</td>
                                                        <td className={ `${ fontSize } pr-4 text-right text-ZBF_green truncate relative list_element element_right` }
                                                            title={ token.uuid }>
                                                            { token.mintHeight === -1 ? (
                                                                    <span className="text-ZBF_green">Pending</span>
                                                            ) : (
                                                                    <Link
                                                                            className="text-ZBF_green hover:underline"
                                                                            to={ URLProvider.URL_TOKEN_DETAIL.replace(
                                                                                    ":tokenId",
                                                                                    token.uuid
                                                                            ) }
                                                                    >
                                                                        { token.uuid }
                                                                    </Link>
                                                            ) }
                                                        </td>
                                                    </tr>
                                            )
                                        }) : <div/>
                                    }
                            >
                            </ListContainer>
                        </div>

                        { myTokens && myTokensBalance && myTokens.length > 10 && (
                                <TokenmintButton
                                        title={ `View all (${ myTokens.length })` }
                                        style={ TokenmintButtonStyle.GREEN_BORDERED }
                                        enabled={ true }
                                        link={ blockExplorerUrl!.concat(`/address/${ address }`) }
                                        newTab
                                />
                        ) }

                        <div className="px-8 pt-8 sm:pb-8 space-y-16 mt-20">
                            <ListContainer
                                    titleSection={ <ListHeader title="All tokens"/> }
                                    elements={ ["Token", "Symbol", "Circulating Supply", "Maximum Supply", "UUID"] }
                                    elementsConfig={ ["1/5", "1/5", "1/5", "1/5", "1/5"] }
                                    isLoading={ allTokensIsLoading || walletConnecting }
                                    isEmpty={ appState.walletState.walletStatus === WalletStatus.CONNECTED && allTokens ? allTokens[0].length === 0 : !allTokensIsLoading && !walletConnecting }
                                    customEmptyMessage={ appState.walletState.walletStatus === WalletStatus.NOT_CONNECTED ? "Connect your wallet to see all available tokens." : "No tokens found" }
                                    tableContent={
                                        allTokens ? allTokens[0].slice(0, 10).map((b: SCAPI.TokenBoxType, index: number) => {
                                            const currentSupplyFormatted = minimumUnitsToFormattedString(b.currentSupply, b.precision)
                                            const maxSupplyFormatted = maxSupplyDisplay(b.maxSupply, b.precision)
                                            const fontSize = "text-base"
                                            return (
                                                    <tr key={ b.uuid }
                                                        className={ (index === allTokens[0].length - 1 ? "" : "border-b")
                                                                .concat(" border-Gray_text/20 h-20 list_container")
                                                                .concat(` hover:bg-Hover_bckgrnd`) }>
                                                        <td className={ `${ fontSize } pl-4 pr-8 text-white font-bold truncate relative list_element element_left` }
                                                            title={ b.name }> { b.name } </td>
                                                        <td className={ `${ fontSize } pr-8 text-white truncate` }>{ b.symbol }</td>
                                                        <td className={ `${ fontSize } pr-8 text-white truncate` }
                                                            title={ currentSupplyFormatted }>{ currentSupplyFormatted }</td>
                                                        <td className={ `${ fontSize } pr-8 text-white truncate` }
                                                            title={ maxSupplyFormatted }>{ maxSupplyFormatted }
                                                        </td>
                                                        <td className={ `${ fontSize } pr-4 text-right text-ZBF_green truncate relative list_element element_right hover:underline` }
                                                            title={ b.uuid }>
                                                            <Link
                                                                    className="text-ZBF_green"
                                                                    to={ URLProvider.URL_TOKEN_DETAIL.replace(
                                                                            ":tokenId",
                                                                            b.uuid
                                                                    ) }
                                                            >
                                                                { b.uuid }
                                                            </Link>
                                                        </td>
                                                    </tr>
                                            )
                                        }) : <div/>
                                    }
                            >
                            </ListContainer>
                        </div>

                        { appState.walletState.walletStatus === WalletStatus.CONNECTED && allTokens && allTokens[0].length > 10 && (
                                <div className="mb-20 flex">
                                    <TokenmintButton
                                            title={ `View all` }
                                            style={ TokenmintButtonStyle.GREEN_BORDERED }
                                            enabled={ true }
                                            link={ blockExplorerUrl!.concat("/tokens") }
                                            newTab
                                    />
                                </div>
                        ) }
                    </div>
                </main>
            </div>
    )
}

export default Tokens
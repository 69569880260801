import React from "react"
import { SCAPI } from "../network/explorer_types/SCAPI"
import { maxSupplyDisplay, minimumUnitsToFormattedString } from "../utils/Utils"
import { FeatureFlag, featureFlags, FeatureFlagType } from "../utils/FeatureFlags"
import { AppState } from "../types/AppState"
import { useSelector } from "react-redux"

export type TokenDetailInfoProps = {
    token: SCAPI.TokenBoxType
    isCollection?: boolean
}

const TokenDetailInfo: React.FC<TokenDetailInfoProps> = (
        {
            token,
            isCollection = false,
        }
) => {

    const blockExplorerUrl = useSelector((state: AppState) => state.blockExplorerUrl)

    const beforeAfterCssRules = `relative after:absolute before:absolute
    after:w-4 before:w-4 after:h-full before:h-full after:border before:border
    after:top-0 before:top-0 after:right-0 before:left-0
    after:border-l-0 before:border-r-0
    after:border-ZBF_green before:border-ZBF_green`

    return (
            <div className="w-full">
                <div className={ `${ beforeAfterCssRules } h-[12rem] text-white font-bold grid-cols-5 grid` }>
                    <div className="col-span-3 space-x-4 grid-cols-3 h-full grid py-8 px-10">
                        <div className="col-span-1 grid-rows-2 grid space-y-2">
                            <div className="row-span-1 truncate">
                                <p className="text-sm text-Gray_text uppercase tracking-[0.2em]">{ isCollection ? "Circulating Items" : "Circulating Supply" }</p>
                                <p className="text-base text-white truncate"
                                   title={ minimumUnitsToFormattedString(token.currentSupply, token.precision) }>{ minimumUnitsToFormattedString(token.currentSupply, token.precision) }</p>
                            </div>
                            <div className="row-span-1 truncate">
                                <p className="text-sm text-Gray_text uppercase tracking-[0.2em]">Maximum Supply</p>
                                <p className="text-base text-white truncate"
                                   title={ minimumUnitsToFormattedString(token.maxSupply, token.precision) }>{ maxSupplyDisplay(token.maxSupply, token.precision) }</p>
                            </div>
                        </div>
                        <div className="col-span-1 grid-rows-2 grid space-y-2">
                            <div className="row-span-1">
                                <p className="text-sm text-Gray_text uppercase tracking-[0.2em]">Explorer</p>
                                <a
                                        className="hover:underline font-normal text-base text-lg mb-auto text-ZBF_green"
                                        target="_blank"
                                        href={ blockExplorerUrl!.concat(isCollection ? `/collection/${ token.uuid }` : `/token/${ token.uuid }`) }
                                >
                                    TokenMint Explorer
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    )
}

export default TokenDetailInfo
export const WALLET_CONNECTED_CLICK = "WALLET_CONNECTED_CLICK"
export const WALLET_DISCONNECT_CLICK = "WALLET_DISCONNECT_CLICK"
export const WALLET_NOT_CONNECTED_CLICK = "WALLET_NOT_CONNECTED_CLICK"
export const WALLET_CONNECTION_STARTED = "WALLET_CONNECTION_STARTED"
export const WALLET_CONNECTION_SUCCESSFUL = "WALLET_CONNECTION_SUCCESSFUL"
export const WALLET_CONNECTION_ERROR = "WALLET_CONNECTION_ERROR"
export const WALLET_CONNECTION_WINDOW_CLOSED = "WALLET_CONNECTION_WINDOW_CLOSED"
export const WALLET_CONNECTED_MODAL_CLOSED = "WALLET_CONNECTED_MODAL_CLOSED"
export const WALLET_CONNECTED_LOADING = "WALLET_CONNECTED_LOADING"
export const WALLET_MODAL_CLOSED = "WALLET_MODAL_CLOSED"

export const TOKEN_DECLARATION_STARTED = "TOKEN_DECLARATION_STARTED"
export const TOKEN_DECLARATION_COMPLETED = "TOKEN_DECLARATION_COMPLETED"
export const TOKEN_DECLARATION_CONFIRMED = "TOKEN_DECLARATION_CONFIRMED"
export const TOKEN_DECLARATION_MODAL_CLOSED = "TOKEN_DECLARATION_MODAL_CLOSED"

export const COLLECTION_DECLARATION_STARTED = "COLLECTION_DECLARATION_STARTED"
export const COLLECTION_DECLARATION_COMPLETED = "COLLECTION_DECLARATION_COMPLETED"
export const COLLECTION_DECLARATION_CONFIRMED = "COLLECTION_DECLARATION_CONFIRMED"

export const TOKEN_MINT_STARTED = "TOKEN_MINT_STARTED"
export const TOKEN_MINT_COMPLETED = "TOKEN_MINT_COMPLETED"
export const TOKEN_MINT_MODAL_CLOSED = "TOKEN_MINT_MODAL_CLOSED"

export const COLLECTION_MINT_STARTED = "COLLECTION_MINT_STARTED"
export const COLLECTION_MINT_COMPLETED = "COLLECTION_MINT_COMPLETED"

export const TOKEN_BURN_STARTED = "TOKEN_BURN_STARTED"
export const TOKEN_BURN_COMPLETED = "TOKEN_BURN_COMPLETED"
export const TOKEN_BURN_MODAL_CLOSED = "TOKEN_BURN_MODAL_CLOSED"

export const CLOSE_MODALS = "CLOSE MODALS"
export const SHOW_MODAL_ERROR = "SHOW_MODAL_ERROR"

export const OPEN_NAV_DRAWER = "OPEN_NAV_DRAWER"
export const CLOSE_NAV_DRAWER = "CLOSE_NAV_DRAWER"

export const OPEN_DESKTOP_ONLY_MODAL = "OPEN_DESKTOP_ONLY_MODAL"
import React, { Dispatch, useEffect, useState } from "react"
import closeIcon from "../assets/close_icon.png"
import walletIcon from "../assets/wallet_icon.svg"
import { WalletStatus } from "../types/WalletState"
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { AppState } from "../types/AppState";
import {
    SHOW_MODAL_ERROR,
    WALLET_CONNECTION_ERROR,
    WALLET_CONNECTION_STARTED,
    WALLET_CONNECTION_SUCCESSFUL,
    WALLET_CONNECTION_WINDOW_CLOSED
} from "../store/actionTypes";
import { Constants } from "../utils/Constants";

export type WalletConnectionModalProps = {
    onModalClose: () => void
}

const WalletConnectionModal: React.FC<WalletConnectionModalProps> = (
        {
            onModalClose,
        }
) => {

    const appState: AppState = useSelector(
            (state: AppState) => state,
            shallowEqual
    )

    const dispatch: Dispatch<any> = useDispatch()

    const [error, setError] = useState<string | undefined>(undefined)
    const [walletExisting, setWalletExisting] = useState<boolean>(false)

    useEffect(() => {
        let walletExists = (window as any).horizen
        setWalletExisting(walletExists !== undefined)

        if (appState.walletState.walletStatus === WalletStatus.ERROR) {
            setError(appState.walletState.walletError)
        }
    }, [])

    const onHowToClick = () => {
        // TODO: Navigate to wherever
    }

    const onWalletClick = async () => {
        setError(undefined)

        if (!walletExisting) {
            window.open(process.env.REACT_APP_WALLET_INSTALL, '_blank')
            return
        }

        dispatch({
            type: WALLET_CONNECTION_STARTED
        })

        const onWalletEmpty = () => {
            dispatch({
                type: SHOW_MODAL_ERROR,
                error: "Make sure you have created a Cobalt wallet account and try again.",
            })
        }

        try {
            const isEmptyWallet = await (window as any).horizen.isEmptyWallet()
            if (isEmptyWallet) {
                onWalletEmpty()
                return
            }

            const walletAddress = await (window as any).horizen.connect()
            const walletBalances = await (window as any).horizen.getBalance()
            const walletNetworkId: string = await (window as any).horizen.getSelectedNetworkId()
            const blockExplorerUrl = parseInt(walletNetworkId) <= Object.keys(Constants.BLOCK_EXPLORER_NETWORK_MAP).length
                    ? (Constants.BLOCK_EXPLORER_NETWORK_MAP as any)[walletNetworkId]
                    : process.env.REACT_APP_SIDECHAIN_URL
            const ZENBalance = walletBalances.find((balance: any) => balance.uuid === Constants.ZENSymbol)
            if (walletAddress !== undefined && walletAddress !== '') {
                dispatch({
                    type: WALLET_CONNECTION_SUCCESSFUL,
                    walletAddress: walletAddress,
                    blockExplorerUrl,
                    ZENBalance: ZENBalance?.balance ?? '0'
                })
            } else {
                dispatch({
                    type: WALLET_CONNECTION_ERROR,
                    error: "Wallet address not found",
                })
            }
        } catch (e: any) {
            if (e.message === "There are no connected wallets") {
                onWalletEmpty()
            } else {
                dispatch({
                    type: WALLET_CONNECTION_WINDOW_CLOSED
                })
            }
        }
    }

    const buttonText = () => {
        if (walletExisting) {
            return "Cobalt Wallet"
        } else {
            return "Install Cobalt Wallet"
        }
    }

    return (
            <div
                    className={ `${ appState.walletState.modalOpen ? "block" : "hidden" } bg-Hover_bckgrnd grid text-center pb-8 pt-6 relative mx-auto max-w-[25vw] top-[calc(10vh)]` }>
                <button className="absolute top-6 right-6 w-8 h-8" onClick={ onModalClose }>
                    <img src={ closeIcon } className="object-contain"/>
                </button>
                <span className="text-3xl font-bold text-white">Connect your wallet</span>
                <span className="text-normal text-white mt-10 bg-Main_bckgrnd mx-6 px-6 py-4">By connecting a wallet, you agree to <a
                        className="hover:underline text-ZBF_green"
                        href={ `${ process.env.REACT_APP_TOKENMINT_URL! }/terms` }>TokenMint Terms of Service</a> and acknowledge that you have read and understood the terms.</span>

                {/*Hiding temporarily until we start making use of third party services like analytics*/ }
                {/*<button*/ }
                {/*        className="flex text-normal text-white mt-12 bg-Main_bckgrnd mx-6 px-6 py-3 border rounded-md border-transparent hover:border-ZBF_green"*/ }
                {/*        onClick={ onHowToClick }>*/ }
                {/*    <img className="mr-3 my-auto"*/ }
                {/*         src={ tooltipIcon }/>*/ }
                {/*    <span>How this app uses APIs</span>*/ }
                {/*    <img*/ }
                {/*            className="ml-auto my-auto h-4 w-4 object-contain"*/ }
                {/*            src={ whiteArrow }/>*/ }
                {/*</button>*/ }

                { error !== undefined && (
                        <div
                                className="flex text-normal text-white mt-5 bg-Main_bckgrnd mx-6 px-6 border rounded-md border-ZBF_red">
                            <span className="font-bold leading-5 my-3">{ error }</span>
                        </div>
                ) }

                <button
                        className="flex text-normal text-white mt-5 bg-Main_bckgrnd mx-6 px-6 border rounded-md border-Gray_text hover:border-ZBF_green"
                        onClick={ onWalletClick }>
                    <span className="font-bold leading-5 my-6">{ buttonText() }</span>
                    <img
                            className="ml-auto my-auto h-7 w-7 object-contain"
                            src={ walletIcon }/>
                </button>
            </div>
    )
}

export default WalletConnectionModal
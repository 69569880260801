import React, { useEffect, useState } from "react"
import { formatNumberString, minimumUnitsToFormattedString, quantityIsValid, toMinimumUnits } from "../utils/Utils"
import TokenmintButton, { TokenmintButtonStyle } from "./Common/TokenmintButton"
import FormInput, { FormInputStyle } from "./Form/FormInput"
import BigNumber from "bignumber.js";
import { AppState } from "../types/AppState";
import { shallowEqual, useSelector } from "react-redux";
import { useQuery } from "react-query";
import { getTxFees } from "../network/APIRepository";
import TokenMintSpinner from "../templates/TokenMintSpinner";
import PendingTokenMessage from "./PendingTokenMessage";

export type TokenCreatorMintInfoProps = {
    tokenUUID: string | undefined
    pendingTokenTransactionId: string | undefined
    precision: number
    maxSupply: BigNumber
    address: string | undefined
    setAddress: (address: string) => void
    //without decimals, in minimum units
    amount: string | undefined
    setAmount: (amount: string) => void

    onSubmit: () => void
}

export const tokenMintFormIsValid = (address: string | undefined, amount: string | undefined, maxSupply: BigNumber, precision: number): { isValid: boolean, error: string | undefined } => {
    if (!addressIsValid(address)) {
        return {isValid: false, error: "Address not valid"}
    }

    return amountIsValid(amount, new BigNumber(0), new BigNumber(toMinimumUnits(maxSupply.toString(10), precision)), precision, true)
}

export const addressIsValid = (address: string | undefined) => {
    const addressRegex = new RegExp("^[a-z0-9]{64}$")
    return address !== undefined && address !== "" && addressRegex.test(address)
}

export const amountFormatIsCorrect = (amount: string | undefined) => {
    const amountRegex = new RegExp("^[0-9]{0,78}$")
    return amountRegex.test(amount ? amount : "")
}

export const amountIsValid = (amount: string | undefined, currentSupply: BigNumber, maxSupply: BigNumber, precision: number, isMint: boolean): { isValid: boolean, error: string | undefined } => {
    if (amount === undefined || amount === "") {
        return {isValid: false, error: undefined}
    }
    if (amount.includes(".")) {
        return {isValid: false, error: "Exceeds decimal precision"}
    }
    if (!amountFormatIsCorrect(amount)) {
        return {isValid: false, error: "Format not valid"}
    }
    const bigNumAmount = new BigNumber(amount!)
    if (bigNumAmount.isZero()) {
        return {
            isValid: false,
            error: isMint ? "Mint quantity must be greater than zero" : "Burn quantity must be greater than zero"
        }
    }

    if (maxSupply.isZero()) {
        return {isValid: true, error: undefined}
    }

    if (isMint) {
        if (currentSupply.plus(bigNumAmount).isGreaterThan(maxSupply)) {
            return {
                isValid: false,
                error: `Resulting amount can not be greater than ${ minimumUnitsToFormattedString(maxSupply.minus(currentSupply), precision) }`
            }
        }
    } else {
        if (currentSupply.isZero()) {
            return {isValid: false, error: "Token has no supply left to burn"}
        }

        if (currentSupply.minus(bigNumAmount).isLessThan(new BigNumber(0))) {
            return {
                isValid: false,
                error: `Amount to burn can not be greater than ${ minimumUnitsToFormattedString(currentSupply, precision) }`
            }
        }
    }

    if (!quantityIsValid(amount)) {
        return {
            isValid: false,
            error: `Maximum value reached`
        }
    }

    return {isValid: true, error: undefined}
}

const TokenCreatorMintInfo: React.FC<TokenCreatorMintInfoProps> = (
        {
            tokenUUID,
            pendingTokenTransactionId,
            precision,
            maxSupply,
            address,
            setAddress,
            amount,
            setAmount,
            onSubmit
        }
) => {

    const appState: AppState = useSelector(
            (state: AppState) => state,
            shallowEqual
    )
    const blockExplorerUrl = useSelector((state: AppState) => state.blockExplorerUrl)

    const [validForm, setValidForm] = useState(false)
    //with decimals
    const [amountFormatted, setAmountFormatted] = useState<string | undefined>(undefined)
    const [addressError, setAddressError] = useState<string | undefined>(undefined)
    const [amountError, setAmountError] = useState<string | undefined>(undefined)

    const {
        data: fees,
        error: feeError,
        isLoading: feesIsLoading
    } = useQuery(['getTxFee'], () => getTxFees(), {retry: false})

    //////////////////// Effects //////////////////////

    useEffect(() => {
        if (feesIsLoading) {
            setValidForm(false)
            return
        }

        const tokenFormValid = tokenMintFormIsValid(address, amount, maxSupply, precision)
        setValidForm(tokenFormValid.isValid && !appState.walletState.pendingTokenCreation)

        if (address) {
            setAddressError(addressIsValid(address) ? undefined : "Invalid format")
        }
        if (amount) {
            const amountValid = amountIsValid(amount, new BigNumber(0), new BigNumber(toMinimumUnits(maxSupply.toString(10), precision)), precision, true)
            setAmountError(amountValid.error)
        }

    }, [address, amount, fees, feeError, appState])

    //////////////////////////////////////////////////////

    const pendingTokenMessage = () => {
        const spinner = (
                <div className="w-12 mx-6 my-auto">
                    <TokenMintSpinner/>
                </div>
        )

        if (pendingTokenTransactionId) {
            return (
                    <div className="mx-auto mt-6 flex">
                        { spinner }
                        <p className="text-white text-base">
                            Hang tight! We’re recording your new token in the next block. Once recorded, you will be
                            able to mint your token. <br/><br/>
                            <p className="flex">
                                Estimated time:
                                <div className="mt-1">&nbsp;~</div>2-3 minutes. Check the status&nbsp;
                                <a className="text-ZBF_green hover:underline"
                                   href={ `${ blockExplorerUrl! }/tx/${ pendingTokenTransactionId! }` }
                                   target="_blank"
                                >here</a>.
                            </p>
                        </p>
                    </div>
            )
        } else {
            return (
                <PendingTokenMessage />
            )
        }
    }

    return (
            <div className="grid mt-16">
                <div className="grid space-y-4">
                    <FormInput title="Mint to address" value={ address } onChange={ setAddress }
                               error={ addressError }
                               titleTooltip={ <p>The address where the minted<br/>tokens will be sent to.</p> }/>
                    <FormInput title="Amount to mint"
                               titleTooltip={
                                   <p>The amount of tokens that will<br/>be minted at this time<br/>
                                       <span className="text-ZBF_green">Available to mint:&nbsp;{ maxSupply.toString(10) }</span>
                                   </p>
                               }
                               value={ amountFormatted } error={ amountError }
                               onChange={ (value) => {
                                   const newValue = value.replace(/[^0-9.]/g, "")
                                   setAmountFormatted(newValue)
                                   setAmount(toMinimumUnits(newValue, precision))
                               } }
                               onFocusLost={ () => {
                                   setAmountFormatted(formatNumberString(amountFormatted))
                               } }
                               onFocus={ () => {
                                   const simpleAmount = amountFormatted ? amountFormatted.replace(/[^0-9.]/g, "") : undefined
                                   setAmountFormatted(simpleAmount)
                               } }/>
                    { feesIsLoading &&
                            <div className="w-10 mx-auto">
                                <TokenMintSpinner/>
                            </div>
                    }
                    { fees &&
                            <FormInput title="Token mint fee"
                                       titleTooltip={ <p>The required fee for a token<br/>minting transaction</p> }
                                       value={ fees.tokenMintFee.toString() }
                                       style={ FormInputStyle.BLACK }
                                       onChange={ () => {
                                       } }
                                       disabled={ true }/>
                    }
                </div>

                { appState.walletState.pendingTokenCreation && (
                        pendingTokenMessage()
                ) }

                <div className="flex mx-auto w-[60%] mt-10">
                    <TokenmintButton
                            title="Mint tokens"
                            style={ TokenmintButtonStyle.GREEN_BORDERED }
                            enabled={ validForm }
                            onClick={ () => {
                                if (!tokenUUID) {
                                    setAmountError("There was an error. Try again from token detail screen")
                                    return
                                }
                                onSubmit()
                            } }>
                    </TokenmintButton>
                </div>
            </div>
    )
}

export default TokenCreatorMintInfo
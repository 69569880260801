import React from "react"
import TokenMintSpinner from "../templates/TokenMintSpinner";

const WalletLoading = () => {
    return (
            <div className="grid justify-center items-center mt-20 text-2xl text-white font-bold">
                <div className="w-14 mb-6 mx-auto">
                    <TokenMintSpinner/>
                </div>
                <h1>
                    Wallet loading
                </h1>
            </div>
    )
}

export default WalletLoading

import React, { useEffect, useState } from "react"
import { useQuery } from "react-query"
import { useNavigate, useParams } from "react-router-dom"
import ListHeader from "../components/ListHeader"
import { getAllCollections, getNFTs } from "../network/APIRepository"
import { SCAPI } from "../network/explorer_types/SCAPI"
import { URLProvider } from "../utils/URLProvider"
import { delay } from "../utils/Utils"
import DashboardButton, { DashboardButtonType } from "../components/DashboardButton";
import TokenDetailInfo from "../components/TokenDetailInfo";
import copyIcon from "../assets/copy_icon.svg"
import ReactTooltip from "react-tooltip";
import { AppState } from "../types/AppState";
import { shallowEqual, useSelector } from "react-redux";
import TokenMintSpinner from "../templates/TokenMintSpinner";
import NFTsList from "../components/NFTsList";

const CollectionDetail: React.FC = () => {
    const params = useParams()
    const navigate = useNavigate()
    const {collectionId} = params
    const appState: AppState = useSelector(
            (state: AppState) => state,
            shallowEqual
    )
    const blockExplorerUrl = useSelector((state: AppState) => state.blockExplorerUrl)

    const [address, setAddress] = useState<string | undefined>(undefined)
    const [tokenOwned, setTokenOwned] = useState(false)

    const {
        data: collection,
        error,
        isLoading
    } = useQuery<SCAPI.TokenBoxType[] | void>(["getCollection", collectionId], () => getAllCollections(collectionId ? [collectionId] : []), {enabled: address !== undefined})

    const {
        data: nfts,
        error: nftsError,
        isLoading: nftsIsLoading
    } = useQuery<[nfts: SCAPI.TokenNonFungibleBoxType[], totalPages: number] | void>(["getNFTsByCollection", collectionId], () => getNFTs(collectionId as string), {enabled: collection !== undefined})

    //////////////////// Effects //////////////////////

    useEffect(() => {
        if (appState.walletState.walletAddress) {
            setAddress(appState.walletState.walletAddress)
        }
    }, [appState])

    useEffect(() => {
        if (collection && address && collection.find((token: SCAPI.TokenBoxType) => token.owner === address)) {
            setTokenOwned(true)
        }
    }, [collection, address])

    //////////////////////////////////////////////////////

    const onMintTokenClick = () => {
        navigate(URLProvider.URL_NFT_MINT, {state: {tokenUUID: collectionId}})
    }

    return (
            <div>
                <main>
                    <div className="max-w-screen-xl ml-auto mr-auto xl:grid">
                        <>
                            { !address && (
                                    <p className="text-center text-Gray_text text-xl mt-40 mb-4">
                                        Connect your wallet to watch your token transactions<br/>
                                        or watch it on{ " " }
                                        <a className="text-ZBF_green hover:underline"
                                        target="_blank"
                                        href={ blockExplorerUrl.concat(`/collection/${ collectionId }`) }>our
                                            block explorer</a>
                                    </p>
                            ) }

                            { isLoading && (
                                    <div className="w-14 mt-20 mx-auto">
                                        <TokenMintSpinner/>
                                    </div>
                            ) }

                            { collection && collection.length > 0 && (
                                    <div className="px-8 grid mt-12">
                                        <span className="text-white text-4xl font-bold">{ collection[0].name }</span>
                                        <span
                                                className="text-Gray_text text-2xl uppercase font-bold mt-1">{ collection[0].symbol }</span>
                                        { collection[0].metadata && collection[0].metadata["description"] && (
                                                <span className="text-white text-base mt-5">{ collection[0].metadata["description"] }</span>
                                        ) }
                                        <span
                                                className="text-white text-Gray_text text-sm uppercase font-bold tracking-[0.2em] mt-5">Unique Id</span>
                                        <div className="flex">
                                            <span className="text-white text-base pt-1">{ collection[0].uuid }</span>
                                            <button
                                                    data-event="click"
                                                    className="ml-2 p-1 rounded border border-transparent hover:border-ZBF_green">
                                                <img
                                                        data-tip="Collection UUID copied to the pasteboard!"
                                                        data-for="description"
                                                        className="w-4 h-4"
                                                        src={ copyIcon }/>
                                                <ReactTooltip
                                                        id="description"
                                                        effect="solid"
                                                        multiline
                                                        event="click"
                                                        afterShow={ async () => {
                                                            navigator.clipboard.writeText(collection[0].uuid)
                                                            await delay(2000)
                                                            ReactTooltip.hide()
                                                        } }
                                                />
                                            </button>
                                        </div>
                                    </div>
                            ) }

                            { collection && collection.length > 0 && (
                                    <div className="px-8 mt-10">
                                        <TokenDetailInfo token={ collection[0] } isCollection/>
                                    </div>
                            ) }

                            { !isLoading && collection && collection.length > 0 && (
                                    <div className="px-8 grid-cols-4 grid space-x-6 mt-10">
                                        { tokenOwned && (
                                                <DashboardButton type={ DashboardButtonType.MINT_NFT }
                                                                onClick={ onMintTokenClick }/>
                                        ) }
                                    </div>
                            ) }

                            { collection && collection.length > 0 && (
                                    <div className="px-8 sm:pb-8 space-y-16 mt-10">
                                        <NFTsList isLoading={ nftsIsLoading }
                                                isEmpty={ nfts !== undefined && nfts[0].length === 0 }
                                                baseURI={ collection[0].baseURI ?? "" }
                                                collectionSymbol={ collection[0].symbol ?? "" }
                                                collectionName={ collection[0].name ?? "" }
                                                titleSection={ <ListHeader title={ `${ collection![0].name } NFTs` }/> }
                                                filteredNFTs={ nfts ? nfts[0] : [] }/>
                                    </div>
                            ) }
                        </>
                    </div>
                </main>
            </div>
    )
}

export default CollectionDetail
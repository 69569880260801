import React, { Dispatch, useEffect, useState } from "react"
import { addressIsValid, amountIsValid, tokenMintFormIsValid } from "../components/TokenCreatorMintInfo"
import { WalletStatus } from "../types/WalletState"
import BigNumber from "bignumber.js"
import { getBalanceByAddress, getMyTokens, getSingleToken, getTransactionsByToken, getTxFees, sendTokenMint } from "../network/APIRepository"
import { Constants } from "../utils/Constants"
import { delay, formatNumberString, minimumUnitsToFormattedString, toMinimumUnits } from "../utils/Utils"
import { AppState, blurBackground } from "../types/AppState"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import {
    CLOSE_MODALS,
    TOKEN_DECLARATION_CONFIRMED,
    TOKEN_MINT_COMPLETED,
    TOKEN_MINT_MODAL_CLOSED,
    TOKEN_MINT_STARTED,
    WALLET_NOT_CONNECTED_CLICK,
} from "../store/actionTypes"
import { URLProvider } from "../utils/URLProvider"
import { useLocation, useNavigate } from "react-router-dom"
import FormInput, { FormInputStyle } from "../components/Form/FormInput"
import TokenmintButton, { TokenmintButtonStyle } from "../components/Common/TokenmintButton"
import FormDropdown from "../components/Form/FormDropdown"
import { useQuery } from "react-query"
import { Balance, TokenchainAPI } from "../network/explorer_types/TokenchainAPI"
import { SCAPI } from "../network/explorer_types/SCAPI"
import TokenMintSpinner from "../templates/TokenMintSpinner"
import PendingTokenMessage from "../components/PendingTokenMessage";
import { TransactionJSON } from "../network/explorer_types/Transaction"

const TokenMint: React.FC = (props: any) => {
    const location = useLocation()
    const navigate = useNavigate()

    const appState: AppState = useSelector((state: AppState) => state, shallowEqual)

    const dispatch: Dispatch<any> = useDispatch()

    const [tokenUUID, setTokenUUID] = useState<string | undefined>(undefined)
    const [currentToken, setCurrentToken] = useState<SCAPI.TokenBoxType | undefined>(undefined)

    // TokenInfo
    const [newSupply, setNewSupply] = useState<string | undefined>(undefined)
    const [supply, setSupply] = useState<string | undefined>(undefined)

    // TokenMint
    const [validForm, setValidForm] = useState(false)
    //with decimals
    const [amountFormatted, setAmountFormatted] = useState<string>("")
    const [walletAddress, setWalletAddress] = useState<string | undefined>(undefined)
    const [address, setAddress] = useState<string | undefined>(undefined)
    const [addressError, setAddressError] = useState<string | undefined>(undefined)
    const [pendingTokenUUID, setPendingTokenUUID] = useState<string | undefined>(undefined)
    const [pendingTokenTransactionId, setPendingTokenTransactionId] = useState<string | undefined>(undefined)
    //without decimals, in minimum units
    const [amount, setAmount] = useState<string | undefined>(undefined)
    const [amountError, setAmountError] = useState<string | undefined>(undefined)

    const {
        data: myTokensBalance,
        error: myTokensBalanceError,
        isLoading: myTokensBalanceIsLoading,
    } = useQuery<any>(["getBalanceByAddress", walletAddress], () => getBalanceByAddress(), {
        enabled: walletAddress !== undefined,
    })

    const {
        data: newlyCreatedToken,
    } = useQuery<SCAPI.TokenBoxType | void>(["getToken", pendingTokenUUID], () => getSingleToken(pendingTokenUUID ?? ""), {
        enabled: pendingTokenUUID !== undefined,
        refetchInterval: 20000,
        onSuccess: ((resp) => {
            if (resp) {
                setPendingTokenUUID(undefined)
                dispatch({
                    type: TOKEN_DECLARATION_CONFIRMED
                })
            }
        })
    })

    const {
        data: myTokens,
        error: myTokensError,
        isLoading: myTokensIsLoading,
    } = useQuery<any>(
        ["getMyTokens", walletAddress, SCAPI.TokenType.Fungible],
        () => getMyTokens(SCAPI.TokenType.Fungible),
        {
            enabled: walletAddress !== undefined,
        }
    )

    const {
        data: fees,
        error: feeError,
        isLoading: feesIsLoading,
    } = useQuery(["getTxFee"], () => getTxFees(), { retry: false })

    //////////////////// Effects //////////////////////

    // Gets address from appState - We use 2 variables as the address form input is editable
    useEffect(() => {
        if (appState.walletState.walletAddress) {
            setWalletAddress(appState.walletState.walletAddress)
            setAddress(appState.walletState.walletAddress)
        }
    }, [appState])

    // Detects UUID as a parameter in case we're navigating from tokenDetail screen
    useEffect(() => {
        if (location.state && (location.state as any)["tokenUUID"]) {
            setTokenUUID((location.state as any)["tokenUUID"])
        }
    }, [])

    useEffect(() => {
        if (myTokens && myTokens.length > 0) {
            if (!tokenUUID) {
                // Sets the first token as the selected one. This way it will appear as default in the dropdown selector
                setCurrentToken(myTokens[0])
            } else {
                // If the tokenUUID is known (navigating from token detail), it will show its info
                const token = myTokens.find((token: SCAPI.TokenBoxType) => token.uuid === tokenUUID)
                setCurrentToken(token)
            }
        }
    }, [myTokens])

    // On new token selected, stores it
    useEffect(() => {
        if (myTokens) {
            const token = myTokens.find((token: SCAPI.TokenBoxType) => token.uuid === tokenUUID)
            setCurrentToken(token)
        }
    }, [tokenUUID])

    const {
        data: tokenTxs,
    } = useQuery<any>(["getTransactionsByToken", pendingTokenUUID!], () => getTransactionsByToken(pendingTokenUUID as string, undefined), {
        onSuccess: ((resp) => {
            const tx: any = resp[0].find((tx: SCAPI.Transaction & TransactionJSON) => tx.typeName === SCAPI.TokenChainTransactionTypes.TokenTypeDeclareTransaction)
            if (tx) {
                const tokenDeclareTx = tx as unknown as TransactionJSON & TokenchainAPI.TokenDeclareTransactionType
                setPendingTokenTransactionId(tokenDeclareTx.txid)
            }
        })
    })

    // On token selected change, finds the supply to show
    useEffect(() => {
        if (currentToken) {
            setAmount(undefined)
            setAmountError(undefined)
            setNewSupply(
                minimumUnitsToFormattedString(new BigNumber(currentToken!.currentSupply as any), currentToken.precision)
            )
            setSupply(currentToken.currentSupply as any)
        }
    }, [currentToken])

    // Checks the validity of the address
    useEffect(() => {
        const addressValid = addressIsValid(address)
        if (!addressValid) {
            setValidForm(false)
        }
        setAddressError(addressValid ? undefined : "Invalid address")
    }, [address])

    // Checks the validity of the amount inserted
    useEffect(() => {
        if (!currentToken) {
            setNewSupply("")
            setValidForm(false)
            setAmountError(undefined)
            return
        }

        if (feesIsLoading) {
            setValidForm(false)
            return
        }

        const amountValid = amountIsValid(
            amount,
            new BigNumber(currentToken!.currentSupply as any),
            new BigNumber(currentToken!.maxSupply as any),
            currentToken.precision,
            true
        )
        setValidForm(amountValid.isValid)
        setValidForm(pendingTokenUUID === undefined)
        setAmountError(amountValid.error)
        amountValid.isValid
            ? setNewSupply(
                  minimumUnitsToFormattedString(
                      new BigNumber(currentToken!.currentSupply as any).plus(new BigNumber(amount!)),
                      currentToken.precision
                  )
              )
            : setNewSupply(
                  minimumUnitsToFormattedString(
                      new BigNumber(currentToken!.currentSupply as any),
                      currentToken.precision
                  )
              ) 
    }, [amount, fees, feeError, pendingTokenUUID])

    useEffect(() => {
        if (currentToken) {
            if (currentToken.mintHeight === -1) {
                setPendingTokenUUID(currentToken.uuid)
            } else {
                setPendingTokenUUID(undefined)
            }
        }
    }, [currentToken])

    

    //////////////////////////////////////////////////////

    const onSubmit = () => {
        if (appState.walletState.walletStatus !== WalletStatus.CONNECTED) {
            dispatch({
                type: WALLET_NOT_CONNECTED_CLICK,
            })
            return
        }

        if (!currentToken) {
            return
        }

        if (!tokenMintFormIsValid(address, amount, new BigNumber(supply!), currentToken.precision)) {
            return
        }

        sendTokenMint(address!, currentToken!.uuid, amount!)
            .then(async (resp) => {
                dispatch({
                    type: TOKEN_MINT_COMPLETED,
                })

                await delay(3000)

                dispatch({
                    type: CLOSE_MODALS,
                })

                navigate(URLProvider.URL_HOME)
            })
            .catch((error) => {
                if (error.message.includes("closed")) {
                    dispatch({
                        type: TOKEN_MINT_MODAL_CLOSED,
                    })
                }
                if (error.message.includes("rejected")) {
                    dispatch({
                        type: CLOSE_MODALS,
                    })
                }
            })

        dispatch({
            type: TOKEN_MINT_STARTED,
        })
    }

    return (
            <div>
                <main>
                    <div
                            className="max-w-screen-xl ml-auto mr-auto grid">
                        <span
                                className={ `${ blurBackground(appState) ? "blur-sm" : "" } mx-auto text-4xl font-bold text-white text-center mt-24` }>Mint tokens</span>
                        <span
                                className={ `${ blurBackground(appState) ? "blur-sm" : "" } mx-auto text-base text-Content_gray text-center mt-8 px-80` }>Mint additional supply of your token. <br/>Read our <a
                                href={ Constants.UserGuideUrl }
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-ZBF_green hover:underline cursor-pointer">Token Minting Guide</a> to learn more about the process.</span>
                        <div className={ `${ blurBackground(appState) ? "blur-sm" : "" } mx-10 px-64 py-10` }>
                            <div className="grid mt-16">
                                <div className="grid space-y-4">
                                    { !currentToken && myTokensIsLoading && (
                                            <div className="w-10 mx-auto">
                                                <TokenMintSpinner/>
                                            </div>
                                    ) }
                                    { currentToken && myTokensBalance && (
                                            <FormDropdown title="Select token"
                                                          rightText={ `Your balance: ${ minimumUnitsToFormattedString(myTokensBalance.find((balance: Balance) => balance.uuid === currentToken!.uuid)?.balance ?? "0", currentToken!.precision) }` }
                                                          allValues={ myTokens }
                                                          value={ currentToken ? currentToken.uuid : tokenUUID }
                                                          onChange={ setTokenUUID }/>
                                    ) }
                                    <FormInput title="Mint to address"
                                               value={ address }
                                               onChange={ setAddress }
                                               error={ addressError }
                                               titleTooltip={ <p>The address where the minted<br/>tokens will be
                                                   sent to</p> }/>
                                    { currentToken && (
                                        <FormInput title="Quantity to mint"
                                               titleTooltip={ 
                                                    <p>The amount of additional tokens<br/>that will be added
                                                   to the<br/>balance<br/>
                                                        <span className="text-ZBF_green">
                                                            Available to mint:&nbsp;
                                                            { currentToken.currentSupply ? minimumUnitsToFormattedString(new BigNumber(currentToken!.maxSupply as any).minus(new BigNumber(currentToken!.currentSupply as any)), currentToken!.precision) : 0 }
                                                        </span>
                                                    </p> 
                                                }
                                               value={ amountFormatted }
                                               error={ amountError }
                                               onChange={(value)=>{
                                                const precision = currentToken ? currentToken.precision : 6
                                                const newValue = value.replace(/[^0-9.]/g, "")
                                                setAmountFormatted(newValue)
                                                setAmount(toMinimumUnits(newValue, precision))
                                            }}
                                            onFocusLost={()=>{
                                                setAmountFormatted(formatNumberString(amountFormatted))
                                            }}
                                            onFocus={()=>{
                                                setAmountFormatted(amountFormatted.replace(/[^0-9.]/g, ""))
                                            }}
                                        />
                                    ) }
                                    <div className="flex">
                                        { currentToken && (
                                                <div className="w-full">
                                                    <FormInput title="Current supply"
                                                               titleTooltip={ <p>The amount of tokens currently<br/>in
                                                                   circulation</p> }
                                                               value={ minimumUnitsToFormattedString(supply, currentToken.precision) }
                                                               disabled
                                                               style={ FormInputStyle.BLACK }
                                                               onChange={ (value) => setSupply }/>
                                                </div>
                                        ) }
                                        { currentToken && (
                                                <div className="w-full">
                                                    <FormInput title="New supply"
                                                               titleTooltip={ <p>The amount of tokens<br/>circulating
                                                                   after the new mint<br/>operation is confirmed</p> }
                                                               value={ newSupply }
                                                               disabled
                                                               style={ FormInputStyle.BLACK }
                                                               onChange={ (value) => {
                                                               } }/>
                                                </div>
                                        ) }
                                    </div>
                                    { feesIsLoading && (
                                            <div className="w-10 mx-auto">
                                                <TokenMintSpinner/>
                                            </div>
                                    ) }
                                    { fees &&
                                            <FormInput title="Token mint fee"
                                                       titleTooltip={ <p>The required fee for a token<br/>minting
                                                           transaction
                                                       </p> }
                                                       value={ fees.tokenMintFee.toString() }
                                                       style={ FormInputStyle.BLACK }
                                                       onChange={ () => {
                                                       } }
                                                       disabled={ true }/>
                                    }
                                </div>

                            { pendingTokenUUID && (
                                <PendingTokenMessage tokenType="nft" transactionId={ pendingTokenTransactionId }/>
                            ) }

                            <div className="flex mx-auto mt-14">
                                <TokenmintButton
                                    title="Confirm"
                                    style={TokenmintButtonStyle.GREEN_BORDERED}
                                    enabled={validForm}
                                    onClick={onSubmit}
                                ></TokenmintButton>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    )
}

export default TokenMint

import React, { useState } from "react";
import { useQuery } from "react-query";
import axios from "axios";
import TokenMintSpinner from "../templates/TokenMintSpinner";
import NoImageIcon from "../assets/no-image-placeholder.png";
import { SCAPI } from "../network/explorer_types/SCAPI";
import { AppState } from "../types/AppState";
import { shallowEqual, useSelector } from "react-redux";

export type NFTItemProps = {
    baseURI: string
    collectionSymbol: string
    collectionName: string
    nft: SCAPI.TokenNonFungibleBoxType
    children?: React.ReactNode
}

const NFTItem: React.FC<NFTItemProps> = (
        {
            baseURI,
            collectionSymbol,
            collectionName,
            nft,
        }
) => {

    const appState: AppState = useSelector(
            (state: AppState) => state,
            shallowEqual
    )
    const blockExplorerUrl = useSelector((state: AppState) => state.blockExplorerUrl)

    const [imageURI, setImageURI] = useState<string | undefined>(undefined)
    const [imageIsLoading, setImageIsLoading] = useState(true);
    const [imageError, setImageError] = useState(false);

    const getValidURL = (url: string) => {
        return url.substring(0, 7) === "ipfs://" ? url.replace("ipfs://", "https://ipfs.io/ipfs/") : url
    }

    const {
        data,
        error,
        isLoading: metadataIsLoading
    } = useQuery<[any[], number] | void>(
            ["getNFTMetadata", baseURI, nft.serialNumber],
            async () => {
                const axiosInstance = axios.create();
                await axiosInstance
                        .get(`${ getValidURL(baseURI) }${ nft.serialNumber }`, {})
                        .then((result) => {
                            if (result.data.image && result.data.image !== "") {
                                setImageURI(getValidURL(result.data.image));
                            } else {
                                setImageError(true)
                                setImageIsLoading(false);
                            }
                        })
                        .catch((e) => {
                            setImageError(true)
                            setImageIsLoading(false);
                        });
            },
            {refetchOnWindowFocus: false}
    )

    return (
            <div className="grid p-4 rounded-md bg-[#0E0E16] border border-[rgba(122,126,140,0.2)] hover:border-ZBF_green">
                <a target={ "_blank" }
                   href={ imageURI ? `${ blockExplorerUrl! }/nft/${ nft.uuid! }/${ nft.serialNumber }` : "" }>
                    <>
                        { imageURI && (
                            <img className={ `object-contain aspect-square ${ imageIsLoading ? "hidden" : "block" }` }
                                 alt={ "NFT image" }
                                 onLoad={ () => {
                                     setImageIsLoading(false);
                                 } }
                                 onError={ () => {
                                     setImageIsLoading(false);
                                 } }
                                 src={ imageURI }/>
                        ) }
                        { (error || imageError) && (
                                <img className="aspect-square"
                                    alt={ "Image not found placeholder" }
                                    src={ NoImageIcon }/>
                        ) }
                        { (metadataIsLoading || imageIsLoading) && (
                                <div className="aspect-square p-20">
                                    <TokenMintSpinner/>
                                </div>
                        ) }
                        <p className="mt-4 text-base text-Content_gray truncate">{ collectionName }</p>
                        <p className="mt-1 text-base text-white font-bold truncate">{ `${ collectionSymbol } #${ nft.serialNumber }` }</p>
                    </>
                </a>
            </div>
    )
}

export default NFTItem
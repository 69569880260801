import React, { useEffect } from "react"
import { Route, Routes, useLocation } from "react-router-dom"
import Modal from "react-modal"
import TokenCreator from "./pages/TokenCreator"
import Tokens from "./pages/Tokens"
import Layout from "./templates/Layout"
import NotFound from "./pages/404"
import { URLProvider } from "./utils/URLProvider"
import TokenDetail from "./pages/TokenDetail"
import LayoutOnePager from "./templates/LayoutOnePager"
import OnePager from "./pages/OnePager"
import Contact from "./pages/Contact"
import Terms from "./pages/Terms"
import TokenMint from "./pages/TokenMint"
import TokenBurn from "./pages/TokenBurn"
import { AppState } from "./types/AppState"
import { shallowEqual, useSelector } from "react-redux"
import { WalletStatus } from "./types/WalletState"
import WalletLoading from "./pages/WalletLoading"
import NFTs from "./pages/NFTs"
import CollectionCreator from "./pages/CollectionCreator/CollectionCreator"
import NFTMint from "./pages/NFTMint"
import CollectionDetail from "./pages/CollectionDetail"
import Version from "./pages/Version"
import { useMatomo } from "@jonkoops/matomo-tracker-react"

function App() {
    Modal.setAppElement("#root")

    const appState: AppState = useSelector((state: AppState) => state, shallowEqual)
    const { trackPageView } = useMatomo()
    const location = useLocation()

    useEffect(() => {
        trackPageView()
    }, [location, trackPageView])

    useEffect(() => {
        window.plausible =
                window.plausible ||
                function() {
                    ;(window.plausible.q = window.plausible.q || []).push(arguments)
                }
    }, [])

    return (
            <Routes>
                {window.location.host.split(".")[0] !== process.env.REACT_APP_SUBDOMAIN ? (
                        <Route path="/" element={<LayoutOnePager />}>
                            <Route index element={<OnePager />} />
                            <Route path={URLProvider.URL_CONTACT} element={<Contact />} />
                            <Route path={URLProvider.URL_CONTACT} element={<Contact />} />
                            <Route path={URLProvider.URL_TERMS} element={<Terms />} />
                            <Route path="*" element={<NotFound />} />
                        </Route>
                ) : (
                        <Route path="/" element={<Layout />}>
                            <Route index element={<Tokens />} />
                            <Route path={URLProvider.URL_NFTs} element={<NFTs />} />
                            {appState.walletState.walletStatus === WalletStatus.CONNECTING && (
                                    <Route path="*" element={<WalletLoading />} />
                            )}
                            {appState.walletState.walletStatus === WalletStatus.CONNECTED && (
                                    <>
                                        <Route path={URLProvider.URL_TOKEN_CREATOR} element={<TokenCreator />} />
                                        <Route path={URLProvider.URL_TOKEN_MINT} element={<TokenMint />} />
                                        <Route path={URLProvider.URL_TOKEN_BURN} element={<TokenBurn />} />
                                        <Route path={URLProvider.URL_TOKEN_DETAIL} element={<TokenDetail />} />
                                        <Route path={URLProvider.URL_COLLECTION_CREATOR}
                                               element={<CollectionCreator />} />
                                        <Route path={URLProvider.URL_COLLECTION_DETAIL}
                                               element={<CollectionDetail />} />
                                        <Route path={URLProvider.URL_NFT_MINT} element={<NFTMint />} />
                                    </>
                            )}
                            <Route path="*" element={<NotFound />} />
                        </Route>
                )}
                <Route path={URLProvider.URL_VERSION} element={<Version />} />
            </Routes>
    )
}

export default App

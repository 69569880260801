import React, { useEffect, useState } from "react"
import { useQuery } from "react-query"
import { Link, useNavigate } from "react-router-dom"
import TokenmintButton, { TokenmintButtonStyle } from "../components/Common/TokenmintButton"
import ListContainer from "../components/ListContainer"
import ListHeader from "../components/ListHeader"
import { getAllCollections, getAllMyNFTs, getMyTokens } from "../network/APIRepository"
import { SCAPI } from "../network/explorer_types/SCAPI"
import { WalletStatus } from "../types/WalletState"
import { URLProvider } from "../utils/URLProvider"
import { maxSupplyDisplay, minimumUnitsToFormattedString } from "../utils/Utils"
import DashboardButton, { DashboardButtonType } from "../components/DashboardButton";
import { AppState, blurBackground } from "../types/AppState";
import { shallowEqual, useSelector } from "react-redux";

const NFTs: React.FC = () => {

    const navigate = useNavigate()
    const appState: AppState = useSelector(
            (state: AppState) => state,
            shallowEqual
    )

    const blockExplorerUrl = useSelector((state: AppState) => state.blockExplorerUrl)

    const [address, setAddress] = useState<string | undefined>(undefined)
    const [walletConnecting, setWalletConnecting] = useState<boolean>(false)
    const [collectionsOwned, setCollectionsOwned] = useState(false)

    const [collectionsFormatted, setCollectionsFormatted] = useState<{ collection: SCAPI.TokenBoxType, amountOwned: number }[] | undefined>(undefined)
    const {
        data: myNFTs,
        error: myNFTsError,
        isLoading: myNFTsIsLoading
    } = useQuery<any[] | void>(["getAllMyNFTs", address], () => getAllMyNFTs(), {
        enabled: address !== undefined
    })

    const {
        data: myCollections,
        error: myCollectionsError,
        isLoading: myCollectionsIsLoading
    } = useQuery<any>(["getMyTokens", address, SCAPI.TokenType.NonFungible], () => getMyTokens(SCAPI.TokenType.NonFungible), {
        enabled: address !== undefined
    })

    const {
        data: allCollections,
        error: allCollectionsError,
        isLoading: allCollectionsIsLoading
    } = useQuery<any>("getAllCollections", () => getAllCollections(undefined, 0), {
        enabled: address !== undefined
    })

    //////////////////// Effects //////////////////////

    useEffect(() => {
        if (appState.walletState.walletAddress) {
            setAddress(appState.walletState.walletAddress)
        }
        setWalletConnecting(appState.walletState.walletStatus === WalletStatus.CONNECTING)
    }, [appState])

    useEffect(() => {
        if (myCollections && myCollections.length > 0) {
            setCollectionsOwned(true)
        }
    }, [myCollections])

    useEffect(() => {
        if (myCollections && myCollections.length > 0) {
            let newCollectionsFormatted: { collection: SCAPI.TokenBoxType, amountOwned: number }[] = []
            myCollections.forEach((collection: SCAPI.TokenBoxType) => {
                const newCollectionsUUIDs = newCollectionsFormatted.map((col: any) => {
                    return col.collection.uuid
                })

                const amountOwned = myNFTs?.filter((nft: any) => nft.proposition.ownerProposition.publicKey === address && nft.uuid === collection.uuid).length ?? 0
                const newElement = {
                    collection,
                    amountOwned
                }

                if (newCollectionsUUIDs.includes(collection.uuid) && collection.mintHeight === -1) {
                    newCollectionsFormatted = newCollectionsFormatted.filter((col: any) => col.uuid !== collection.uuid)
                    newCollectionsFormatted.push(newElement)
                } else if (!newCollectionsUUIDs.includes(collection.uuid)) {
                    newCollectionsFormatted.push(newElement)
                }
            })
            setCollectionsFormatted(newCollectionsFormatted)
        }
    }, [myCollections, myNFTs])

    //////////////////////////////////////////////////////

    const onCreateCollectionClick = () => {
        navigate(URLProvider.URL_COLLECTION_CREATOR)
    }
    const onMintNFTClick = () => {
        navigate(URLProvider.URL_NFT_MINT)
    }

    return (
            <div>
                <main>
                    <div className={ `${ blurBackground(appState) ? "blur-sm" : "" } max-w-screen-xl ml-auto mr-auto xl:grid` }>
                        { appState.walletState.walletStatus === WalletStatus.NOT_CONNECTED && (
                                <div className="px-8 sm:pb-8 mt-20">
                                    <ListHeader title="Connect wallet to get started"/>
                                    <p className="text-base text-Gray_text">
                                        If you don’t have the Cobalt Wallet extension you can download it <a
                                            className="text-ZBF_green hover:underline"
                                            href={ process.env.REACT_APP_WALLET_INSTALL! }>here.</a>
                                    </p>
                                </div>
                        ) }

                        { appState.walletState.walletStatus === WalletStatus.CONNECTED && (
                                <div className="px-8 grid-cols-4 lg:grid xl:grid flex space-x-6 mt-12">
                                    <DashboardButton type={ DashboardButtonType.CREATE_COLLECTION }
                                                     onClick={ onCreateCollectionClick }/>
                                    { collectionsOwned && (
                                            <DashboardButton type={ DashboardButtonType.MINT_NFT }
                                                             onClick={ onMintNFTClick }/>
                                    ) }
                                </div>
                        ) }

                        <div className="px-8 sm:pb-8 space-y-16 mt-20">
                            <ListContainer
                                    titleSection={ <ListHeader title="Your collections"/> }
                                    elements={ ["Collection", "Symbol", "Circulating items", "Maximum items", "Owned", "UUID"] }
                                    elementsConfig={ ["1/6", "1/6", "1/6", "1/6", "1/6", "1/6"] }
                                    isLoading={ myCollectionsIsLoading || myNFTsIsLoading || walletConnecting }
                                    isEmpty={ appState.walletState.walletStatus === WalletStatus.CONNECTED && collectionsFormatted ? collectionsFormatted.length === 0 && !myCollections : !walletConnecting }
                                    customEmptyMessage={ appState.walletState.walletStatus === WalletStatus.NOT_CONNECTED ? "Connect your wallet to see your collections." : "No collection has been created yet" }
                                    tableContent={
                                        (collectionsFormatted && collectionsFormatted?.length > 0)
                                                ? collectionsFormatted.slice(0, 10).map((collection: { collection: SCAPI.TokenBoxType, amountOwned: number }, index: number) => {
                                                    const currentSupplyFormatted = minimumUnitsToFormattedString(collection.collection.currentSupply, collection.collection.precision)
                                                    const maxSupplyFormatted = maxSupplyDisplay(collection.collection.maxSupply, collection.collection.precision)
                                                    const fontSize = "text-base"
                                                    return (
                                                            <tr key={ collection.collection.uuid }
                                                                className={ (index === collectionsFormatted.length - 1 ? "" : "border-b")
                                                                        .concat(" border-Gray_text/20 h-20 list_container")
                                                                        .concat(` hover:bg-Hover_bckgrnd`) }>
                                                                <td className={ `${ fontSize } pl-4 pr-8 relative list_element element_left text-white truncate font-bold` }
                                                                    title={ collection.collection?.name }>
                                                                    { collection.collection?.name }
                                                                </td>

                                                                <td className={ `${ fontSize } pr-8 text-white truncate` }>{ collection.collection.symbol }</td>
                                                                <td className={ `${ fontSize } pr-8 text-white truncate` }
                                                                    title={ currentSupplyFormatted }>{ currentSupplyFormatted }</td>
                                                                <td className={ `${ fontSize } pr-8 text-white truncate` }
                                                                    title={ maxSupplyFormatted }>{ maxSupplyFormatted }
                                                                </td>
                                                                <td className={ `${ fontSize } pr-8 text-white truncate` }
                                                                >{ collection.amountOwned ?? 0 }</td>
                                                                <td className={ `${ fontSize } pr-4 text-right text-ZBF_green truncate relative list_element element_right` }
                                                                    title={ collection.collection.uuid }>
                                                                    { collection.collection.mintHeight === -1 ? (
                                                                            <span className="text-ZBF_green">Pending</span>
                                                                    ) : (
                                                                            <Link
                                                                                    className="text-ZBF_green hover:underline"
                                                                                    to={ URLProvider.URL_COLLECTION_DETAIL.replace(
                                                                                            ":collectionId",
                                                                                            collection.collection.uuid
                                                                                    ) }
                                                                            >
                                                                                { collection.collection.uuid }
                                                                            </Link>
                                                                    ) }
                                                                </td>
                                                            </tr>
                                                    )
                                                }) : <div/>
                                    }
                            >
                            </ListContainer>
                        </div>

                        { collectionsFormatted && collectionsFormatted.length > 10 && (
                                <div className="flex">
                                    <TokenmintButton
                                            title={ `View all (${ collectionsFormatted.length })` }
                                            style={ TokenmintButtonStyle.GREEN_BORDERED }
                                            enabled={ true }
                                            link={ blockExplorerUrl!.concat(`/address/${ address }`) }
                                            newTab
                                    />
                                </div>
                        ) }

                        <div className="px-8 pt-8 sm:pb-8 space-y-16 mt-20">
                            <ListContainer
                                    titleSection={ <ListHeader title="All collections"/> }
                                    elements={ ["Collection", "Symbol", "Circulating Items", "Maximum Items", "UUID"] }
                                    elementsConfig={ ["1/5", "1/5", "1/5", "1/5", "1/5"] }
                                    isLoading={ allCollectionsIsLoading || walletConnecting }
                                    isEmpty={ appState.walletState.walletStatus === WalletStatus.CONNECTED && allCollections ? allCollections.length === 0 : !allCollectionsIsLoading && !walletConnecting }
                                    customEmptyMessage={ appState.walletState.walletStatus === WalletStatus.NOT_CONNECTED ? "Connect your wallet to see all available collections." : "No collections found" }
                                    tableContent={
                                        allCollections ? allCollections.slice(0, 10).map((b: SCAPI.TokenBoxType, index: number) => {
                                            const currentSupplyFormatted = minimumUnitsToFormattedString(b.currentSupply, b.precision)
                                            const maxSupplyFormatted = maxSupplyDisplay(b.maxSupply, b.precision)
                                            const fontSize = "text-base"
                                            return (
                                                    <tr key={ b.uuid }
                                                        className={ (index === allCollections.length - 1 ? "" : "border-b")
                                                                .concat(" border-Gray_text/20 h-20 list_container")
                                                                .concat(` hover:bg-Hover_bckgrnd`) }>
                                                        <td className={ `${ fontSize } pl-4 pr-8 text-white font-bold truncate relative list_element element_left` }
                                                            title={ b.name }> { b.name } </td>
                                                        <td className={ `${ fontSize } pr-8 text-white truncate` }>{ b.symbol }</td>
                                                        <td className={ `${ fontSize } pr-8 text-white truncate` }
                                                            title={ currentSupplyFormatted }>{ currentSupplyFormatted }</td>
                                                        <td className={ `${ fontSize } pr-8 text-white truncate` }
                                                            title={ maxSupplyFormatted }>{ maxSupplyFormatted }
                                                        </td>
                                                        <td className={ `${ fontSize } pr-4 text-right text-ZBF_green truncate relative list_element element_right hover:underline` }
                                                            title={ b.uuid }>
                                                            <Link
                                                                    className="text-ZBF_green"
                                                                    to={ URLProvider.URL_COLLECTION_DETAIL.replace(
                                                                            ":collectionId",
                                                                            b.uuid
                                                                    ) }
                                                            >
                                                                { b.uuid }
                                                            </Link>
                                                        </td>
                                                    </tr>
                                            )
                                        }) : <div/>
                                    }
                            >
                            </ListContainer>
                        </div>

                        { appState.walletState.walletStatus === WalletStatus.CONNECTED && allCollections && allCollections.length > 10 && (
                                <div className="mb-20 flex">
                                    <TokenmintButton
                                            title={ `View all` }
                                            style={ TokenmintButtonStyle.GREEN_BORDERED }
                                            enabled={ true }
                                            link={ blockExplorerUrl!.concat("/collections") }
                                            newTab
                                    />
                                </div>
                        ) }
                    </div>
                </main>
            </div>
    )
}

export default NFTs
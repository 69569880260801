import React, { Dispatch, useEffect } from "react"
import Modal from "react-modal"
import { Outlet } from "react-router-dom"
import AppFooter from "../components/AppFooter"
import Footer from "../components/Footer/Footer/Footer"
import Header from "../components/Header"
import WalletConnectionModal from "../components/WalletConnectionModal"
import TokenCreatorCreationModal from "../components/TokenCreatorCreationModal";
import { getWalletAddress } from "../network/APIRepository";
import { AppState } from "../types/AppState";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
    TOKEN_DECLARATION_MODAL_CLOSED,
    WALLET_CONNECTED_LOADING,
    WALLET_CONNECTED_MODAL_CLOSED,
    WALLET_CONNECTION_SUCCESSFUL,
    WALLET_DISCONNECT_CLICK,
    WALLET_MODAL_CLOSED
} from "../store/actionTypes";
import WalletModal from "../components/WalletModal";
import { getBoolean, LocalStorageKey } from "../components/Common/LocalStorageWrapper";
import { Constants } from "../utils/Constants";
import SkinnyBanner from "../components/SkinnyBanner";
import { Helmet } from "react-helmet"

const Layout: React.FC<{ children?: any }> = ({children}) => {

    const appState: AppState = useSelector(
            (state: AppState) => state,
            shallowEqual
    )

    const dispatch: Dispatch<any> = useDispatch()

    useEffect(() => {
        const isConnected = getBoolean(LocalStorageKey.IS_CONNECTED)
        if (isConnected) {
            dispatch({
                type: WALLET_CONNECTED_LOADING
            })
            getWalletAddress()
                    .then(async (address) => {
                        const walletBalances = await (window as any).horizen.getBalance()
                        const walletNetworkId = await (window as any).horizen.getSelectedNetworkId()
                        const blockExplorerUrl = parseInt(walletNetworkId) <= Object.keys(Constants.BLOCK_EXPLORER_NETWORK_MAP).length
                                ? (Constants.BLOCK_EXPLORER_NETWORK_MAP as any)[walletNetworkId]
                                : process.env.REACT_APP_SIDECHAIN_URL
                        const ZENBalance = walletBalances.find((balance: any) => balance.uuid === Constants.ZENSymbol)
                        dispatch({
                            type: WALLET_CONNECTION_SUCCESSFUL,
                            walletAddress: address,
                            blockExplorerUrl,
                            ZENBalance: ZENBalance?.balance
                        })
                    })
                    .catch(() => {
                        // It can happen that the user uninstalled the wallet after connecting it
                        // It also happens when the user switchs network in the wallet to another that's not connected
                        dispatch({
                            type: WALLET_DISCONNECT_CLICK
                        })
                    })
        }
    }, [])

    const onWalletModalClose = () => {
        dispatch({
            type: WALLET_MODAL_CLOSED
        })
    }

    const onWalletConnectedModalClose = () => {
        dispatch({
            type: WALLET_CONNECTED_MODAL_CLOSED
        })
    }

    const onCreationModalClose = () => {
        dispatch({
            type: TOKEN_DECLARATION_MODAL_CLOSED
        })
    }

    const bannerEnabled = process.env?.REACT_APP_WARNING_BANNER_VISIBLE === "true"

    return (
            <div className="bg-Main_bckgrnd min-h-[100vh] flex flex-col">
                <div className="absolute bg-ZBF_green opacity-60 blur-layout w-full h-16"/>
                { bannerEnabled && (
                        <SkinnyBanner/>
                ) }
                <Helmet>
                    <script
                            defer
                            data-domain={ process.env.REACT_APP_PLAUSIBLE_SUBDOMAIN }
                            src="https://plausible.io/js/plausible.js"
                    ></script>
                </Helmet>
                <Header/>
                { children }
                <Outlet/>
                <AppFooter/>
                <Footer/>
                <Modal
                        style={ {overlay: {background: "rgba(145, 149, 152, 0.15)"}} }
                        className="bg-transparent min-w-0 h-0"
                        isOpen={ appState.walletState.modalOpen }
                        shouldCloseOnOverlayClick
                        onRequestClose={ onWalletModalClose }
                >
                    <WalletConnectionModal onModalClose={ onWalletModalClose }/>
                </Modal>
                <Modal
                        style={ {overlay: {background: "rgba(145, 149, 152, 0.15)"}} }
                        className="bg-transparent min-w-0 h-0"
                        isOpen={ appState.modalOpen }
                        shouldCloseOnOverlayClick
                        onRequestClose={ onCreationModalClose }
                >
                    <TokenCreatorCreationModal open={ appState.modalOpen }
                                               state={ appState.tokenCreationStatus }
                                               onModalClose={ onCreationModalClose }
                                               errorMessage={ appState.tokenCreationError }/>
                </Modal>
                <Modal
                        style={ {overlay: {background: "rgba(145, 149, 152, 0.15)"}} }
                        className="bg-transparent min-w-0 h-0"
                        isOpen={ appState.walletState.modalConnectedOpen }
                        shouldCloseOnOverlayClick
                        onRequestClose={ onWalletConnectedModalClose }
                >
                    <WalletModal onModalClose={ onWalletConnectedModalClose }/>
                </Modal>
            </div>
    )
}

export default Layout
